.plan-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .plan-card {
    border-radius: var(--br-xl);
    background-color: var(--backgroundprimary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 346px;
    padding: var(--padding-8xs) var(--padding-3xs) var(--padding-3xs);
    box-sizing: border-box;
    gap: var(--gap-11xl);
    font-size: var(--font-size-xl);
    color: var(--secondarymain);
  }

  .titlepicture {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: -8px;
    justify-content: center;
    position: relative;
    gap: var(--gap-3xs);
    z-index: 0;
    color: var(--backgroundprimary);
    width: -moz-fit-content;
    width: fit-content;
  }

  .image-placeholder-icon {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-xl);
    height: 247px;
    max-width: 275px;
    object-fit: contain;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 0;
    width: 100%;
  }
  .family,
  .standard {
    position: absolute;
    margin: 0 !important;
  }
  .family {
    top: 10px;
    right: 121px;
    font-weight: 500;
    z-index: 1;
    font-family: 'Montserrat';
  }
  .standard {
    font-size: var(--font-size-17xl);
    display: inline-block;
    z-index: 2;
    font-family: 'Montserrat';
    padding: 15px;
    bottom: 20px;
    font-weight: 600;
  }

  .renewed-yearly {
    position: relative;
    font-size: var(--font-size-sm);
    display: inline-block;
    color: var(--labelprimary);
    margin-top: -10px;
  }
  .plan-card .price {
    height: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    font-size: var(--font-size-45xl);
  }
  .plan-card .egp {
    position: absolute;
    margin: 0 !important;
    top: 0;
    font-weight: 500;
    z-index: 2;
    left: 111px;
  }

  .height-fit{
    height: fit-content !important;
  }
  
  .plan-features{
    font-weight: 600;
    list-style: disc;
    font-size: calc(14px + 0.1vw);
  }