 .cart-view .medicine-1-icon {
   position: relative;
   width: 109px;
   height: 109px;
   overflow: hidden;
   flex-shrink: 0;
   width: 15%;
 }

 .cart-view .product-image-placeholder {
   border-radius: var(--br-3xs);
   background-color: var(--color-white);
   border: 2px solid var(--secondarymain);
   box-sizing: border-box;
   width: 141px;
   height: 130px;
   overflow: hidden;
   flex-shrink: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: var(--padding-28xl) var(--padding-11xl);
 }

 .cart-view .product-description-product,
 .cart-view .product-name-placeholder {
   position: relative;
   display: flex;
   align-items: center;
   flex-shrink: 0;
   font-size: larger;
 }

 .width60per {
   width: 60%;
 }

 .width45per {
  width: 45%;
}

.width55per{
  width: 55%;
}

 .cart-view .product-description-product {
   font-size: 15px;
   font-family: "Montserrat";
   font-weight: 500;
   color: rgba(66, 66, 66, 1);
 }

 .cart-view .product-details,
 .cart-view .product-detailsplaceholder {
   flex-shrink: 0;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   gap: var(--gap-base);
 }

 .cart-view .product-details {
   width: 30%;
 }

 .cart-view .product-detailsplaceholder {
   flex-direction: row;
   align-items: center;
   gap: var(--gap-11xl);
   z-index: 0;
   width: 100%;
   flex-wrap: wrap;
 }

 .cart-view .plus-symbol-child {
   position: relative;
   width: 17px;
   height: 16px;
   object-fit: cover;
 }

 /* .cart-view .minus-symbol-child {
   position: relative;
   max-height: 100%;
   width: 17px;
   object-fit: cover;
 } */

 .cart-view .egp {
   position: relative;
   font-weight: 600;
   color: var(--secondarymain);
 }

 .cart-view .price {
   top: 70px;
   right: 268px;
   flex-shrink: 0;
   flex-direction: column;
   z-index: 2;
   font-size: var(--font-size-mini);
   color: var(--color-black);
 }

 .cart-view .product-detailsplaceholder1 {
   flex-shrink: 0;
   gap: var(--gap-11xl);
 }

 .cart-view .add-to-cart1,
 .cart-view .product-detailsplaceholder-group,
 .cart-view .product-detailsplaceholder1 {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
 }

 .cart-view .add-to-cart1 {
   flex-shrink: 0;
   font-size: var(--font-size-13xl);
   color: var(--color-white);
 }

 .minus-add-div{
    width: 12%;
 }

 .cart-view .product-detailsplaceholder-group {

   top: 344px;
   left: 1px;
   background-color: var(--color-white);
   border-bottom: 3px solid var(--secondarymain);
   box-sizing: border-box;
   width: 1440px;
   padding: var(--padding-11xl) var(--padding-xl);
   gap: var(--gap-731xl);
   font-size: var(--font-size-xl);
 }

 .cart-view .check-cart-items {

   top: 105px;
   left: 394px;
   font-weight: 600;
   color: var(--color-deepskyblue);
 }

 .cart-view .checkout,
 .cart-view .payment-details {

   top: 105px;
   font-weight: 600;
   color: var(--color-gainsboro);
 }

 .cart-view .payment-details {
   left: calc(50% - 63px);
 }

 .cart-view .checkout {
   right: 423px;
 }

 .arrow-forward-ios-icon {
   position: relative;
   width: 24px;
   height: 24px;
   overflow: hidden;
   flex-shrink: 0;
   object-fit: cover;
 }

 .cart-view .price2 {
   top: 410px;
   right: 271px;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   color: var(--color-black);
 }

 .cart-view .total {
   position: relative;
   font-weight: 500;
 }

 .cart-view .price4 {

   top: 585px;
   right: 271px;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   gap: var(--gap-3xs);
   text-align: center;
   font-size: var(--font-size-xl);
   color: var(--color-black);
 }

 .cart-view .arrow-back-icon {
   position: relative;
   width: 38px;
   height: 38px;
   overflow: hidden;
   flex-shrink: 0;
   object-fit: cover;
 }

 .cart-view {
   background-color: var(--color-white);
   width: 100%;
   overflow: hidden;
   text-align: left;
   font-size: var(--font-size-mini);
   color: var(--secondarymain);
   font-family: var(--font-montserrat);
   padding: 0 1%;
}

 .width62per {
   width: 62% !important;
 }

 .margin-bottom6rem {
   margin-bottom: 6rem;
 }

 .cart-empty-div {
   min-height: calc(100vh - 314px);
 }

 .cart-empty-img {
   position: relative;
   top: 15vh;
 }

 .empty-cart-return {
   position: absolute !important;
   bottom: 230px !important;
 }

 @media screen and (max-width: 950px) {
  .card-checkout-container {
    width: 100% !important;
  }

  .cart-content{
    width: 100% !important;
  }

  .bar-text{
    gap: 23% !important;
    width: 75%;
  }
  
  .steps-bar-icon {
    width:75% !important;
  }
 }