.patient-star {
  display: inline-flex;
  position: relative;
  gap: 25px;
}

.patient-star .class-4 {
  color: var(--labelquaternary) !important;
}

.patient-star .class-5 {
  height: 165.29px !important;
  width: 165.29px !important;
}

.patient-star .class-6 {
  height: 165.29px !important;
  transform: rotate(-90deg) !important;
  width: 165.29px !important;
}

.patient-star.state-on-hover {
  align-items: center;
}

.patient-star.state-default {
  align-items: flex-start;
}
