.password-input {
  align-items: center;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  padding: 10px 20px;
  position: relative;
  width: 452px;
}

.password-input .element {
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5px + 0.23vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.eye-svg{
  position: absolute;
  top: 13px;
}

.eye-svg-left{
  left:90%;
}

.eye-svg-right{
  right:90%
}

.password-input .visibility {
  height: 24px !important;
  left: 412px !important;
  position: absolute !important;
  top: 12px !important;
  width: 24px !important;
}

.password-input.property-1-0-empty {
  border-color: var(--mid-gray);
}

.pointer-hover:hover{
  cursor: pointer;
}

.password-input.property-1-0-password {
  border-color: var(--labelprimary);
}

.password-input.property-1-0-passwordvisible {
  border-color: var(--labelprimary);
}

.password-input.property-1-0-empty .element {
  color: var(--mid-gray);
}

.password-input.property-1-0-password .element {
  color: var(--labelprimary);
}

.password-input.property-1-0-passwordvisible .element {
  color: var(--labelprimary);
}
