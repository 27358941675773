.pharmacy-slider {
    background-color: var(--primarysecondshade);
    height: 300px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .pharmacy-slider .overlap-group {
    background-color: var(--teritiarysecondshade);
    height: 300px;
    /* position: absolute; */
    top: 0;
    width: 100%;
  }
  
  .pharmacy-slider .arrow-back-ios {
    height: 30px !important;
    left: 20px !important;
    position: absolute !important;
    top: 89px !important;
    width: 30px !important;
  }
  
  .pharmacy-slider .arrow-forward-ios {
    height: 30px !important;
    left: 1390px !important;
    position: absolute !important;
    top: 89px !important;
    width: 30px !important;
  }
  
  .pharmacy-slider .slider-spheres-instance {
    left: 665px !important;
    position: absolute !important;
    top: 260px !important;
  }
  
  .pharmacy-slider .text-wrapper {
    color: var(--labelprimary);
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 20px;
    left: 90px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
  }
  
  .pharmacy-slider .text-wrapper-2 {
    color: var(--secondarymain);
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 29px;
    left: 90px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
  }
  
  .pharmacy-slider .p {
    color: var(--labelprimary);
    font-family: "Montserrat", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    left: 90px;
    letter-spacing: -0.21px;
    line-height: normal;
    position: absolute;
    top: 84px;
  }
  
  .pharmacy-slider .overlap {
    background-image: url(/public/img/https-lottiefiles-com-81900-medicaments.gif);
    background-position: 50% 50%;
    background-size: cover;
    height: 300px;
    left: 891px;
    position: absolute;
    top: 0;
    width: 444px;
  }
  
  .pharmacy-slider .https-lottiefiles {
    height: 78px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 65px;
    width: 78px;
  }
  
  .pharmacy-slider .right-3 {
    height: 300px;
    position: absolute;
    top: 0;
    width: 1440px;
  }
  
  .pharmacy-slider .text-wrapper-3 {
    color: var(--backgroundprimary);
    font-family: "Montserrat", Helvetica;
    font-size: 16px;
    font-weight: 500;
    height: 20px;
    left: 90px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 25px;
  }
  
  .pharmacy-slider .text-wrapper-4 {
    color: var(--backgroundprimary);
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 600;
    height: 29px;
    left: 90px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
  }
  
  .pharmacy-slider .our-smart-system {
    color: var(--backgroundprimary);
    font-family: "Montserrat", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 34px;
    left: 90px;
    letter-spacing: -0.21px;
    line-height: normal;
    position: absolute;
    top: 84px;
  }
  
  .pharmacy-slider .https-app {
    height: 300px;
    left: 955px;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 346px;
  }
  
  .pharmacy-slider .overlap-group-2 {
    height: 300px;
    left: 912px;
    position: absolute;
    top: 0;
    width: 415px;
  }
  
  .pharmacy-slider .img {
    height: 264px;
    left: 17px;
    mix-blend-mode: multiply;
    object-fit: cover;
    position: absolute;
    top: 36px;
    width: 398px;
  }
  
  .pharmacy-slider .https-lottiefiles-2 {
    height: 189px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 185px;
  }
  
  .pharmacy-slider.property-1-1-right {
    left: -2960px;
  }
  
  .pharmacy-slider.property-1-1-middle {
    left: -1480px;
  }
  
  .pharmacy-slider.property-1-1-right .overlap-group {
    left: -1480px;
  }
  
  .pharmacy-slider.property-1-1-left .overlap-group {
    left: 1480px;
  }
  
  .pharmacy-slider.property-1-1-middle .overlap-group {
    left: 0;
  }
  
  .pharmacy-slider.property-1-1-right .right-3 {
    background-color: var(--primarymain);
    left: 0;
  }
  
  .pharmacy-slider.property-1-1-left .right-3 {
    background-color: var(--secondarysecondshade);
    left: 2960px;
  }
  
  .pharmacy-slider.property-1-1-middle .right-3 {
    background-color: var(--secondarysecondshade);
    left: 1480px;
  }