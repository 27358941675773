.image-container-card{
    width: 100%;
    /* background-color: var(--navbar); */
    /* background-image: url('/public/img/Background PNG.png'); */
    border-radius: 12;
    border-radius: 3%;
    padding: 7px 0px;
    background-size: 381px;
    /* height: 238px; */
}

@media screen and (max-width: 950px) {

    .clinic-div,
    .home-visit-div,
    .tele-health-div {
        top: 0;
    }

    .doctor-cards {
        margin-top: 49px;
    }

    .doctor-card {
        width: 100%;
    }

    .doctor-name-placeholder{
        height: fit-content;
    }
    
    .doctor-name-placeholder1{
        height: fit-content;
    }
}