.navbar-button-clinic {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* padding: 39px 0; */
  height: 77px;
  font-size: calc(10px + 0.4vw);
}

.navbar-button-sign-up,
.navbar-button-subscribe,
.navbar1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar1 {
  gap: 4%;
  margin: auto;
  padding-top: 8px;
}

.navbar-button-sign-up,
.navbar-button-subscribe {
  border-radius: var(--br-8xs);
  border: 2px solid var(--secondarymain);
  height: 31px;
  padding: var(--padding-8xs) var(--padding-mini);
}

.navbar-button-sign-up:hover {
  background-color: var(--secondarymain);
  border: none;
  color: var(--backgroundprimary);
  cursor: pointer;
  font-weight: 600;
}

.navbar-button-subscribe:hover {
  background-color: var(--subscriptionratingprimary);
  border: none;
  color: var(--backgroundprimary);
  cursor: pointer;
  font-weight: 600;
}

.navbar-button-sign-up:active {
  background-color: var(--secondarysecondshade);
  border: none;
  color: var(--backgroundprimary);
  cursor: pointer;
}

.navbar-button-sign-up:active div,
.navbar-button-sign-up:hover div {
  font-weight: 600;
}

.clinic:hover a,
.services-button:hover .clinic {
  font-weight: 500;
  color: var(--secondarysecondshade);
  cursor: pointer;
}

.clinic:active a,
.services-button:active .clinic {
  font-weight: 600;
  color: var(--secondarysecondshade);
  cursor: pointer;
}

.clinic a {
  width: max-content;
  display: block;
}

.navbar-button-subscribe:active {
  background-color: var(--subscriptionratingprimary);
  border: none;
  color: var(--backgroundprimary);
  cursor: pointer;
  font-weight: 600;
}

.navbar-button-subscribe {
  border: 2px solid var(--subscriptionratingprimary);
  width: 133px;
  font-size: var(--font-size-lg);
  color: var(--subscriptionratingprimary);
}

.cta {
  /* height: 76px; */
  gap: var(--gap-mini);
}

.cta,
.navbar,
.services-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.services-button {
  align-self: stretch;
  padding: var(--padding-8xs) var(--padding-3xs);
}

.navbar {
  backdrop-filter: blur(30px);
  width: 100%;
  box-sizing: border-box;
  gap: 2.5%;
  padding: 0 !important;
}

.search3 {
  position: relative;
  font-weight: 600;
}

.search-bar {
  border-radius: 0 0 0 var(--br-3xs);
  background-color: var(--primaryfirstshade);
  width: 140px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--font-size-lg);
  margin-left: auto;
  position: fixed;
  top: 92px;
  z-index: 5;
}

.navbarsearch-bar {
  position: relative;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--secondarymain);
  z-index: 4;
  background-color: var(--navbar);
}

.search-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

a {
  color: var(--secondarymain);
}

.logo-icon1 {
  width: 148px;
  position: relative;
  margin: auto;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  cursor: pointer;
}

.hello-mesg {
  font-family: "Montserrat";
  font-weight: 500;
  display: contents;
  font-size: calc(10px + 0.5vw);
}

.hello {
  color: var(--labelprimary);
}

.name {
  color: var(--primarymain);
}

.active-nav {
  border-bottom: #00A7E1 4px solid;
}

.active-text {
  color: #2A7DE1;
}

.menu-icon{
  width: 44px;
  margin-top: 12px;
}

.none-web-view{
  display: none !important;
}

@media screen and (max-width: 950px) {
  .cta {
    width: auto;
  }

  .rectangle-arrow {
    right: -40vw !important;
  }
}

.cart-icon{
  width: calc(0.5vw + 22px);
}

.non-active-navbtn{
  padding-bottom: 3px;
}

.savingsIcon{
  width: 4vw;
  max-width: 60px;
  min-width: 41px;
}

@media screen and (max-width: 700px) {
  .navbar{
    align-items: flex-start;
    justify-content: flex-start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .cart-icon{
    margin-top: 20px;
  }

  .doctor-img-ask {
    width: 116px !important;
    height: 116px !important;
  }

  .menu-icon{
    width: 44px;
    margin-top: 8px;
    display: block;
  }

  .none-mobile-view{
    display: none !important;
  }

  .none-web-view{
    display: flex !important;
  }

  .slogan-logo{
    margin:auto !important;
    min-width: 125px !important;
  }

  .ask-doc-div-en, .ask-doc-div-ar{
    width: 86% !important;
    height: auto !important;
    padding: 18px 20px !important;
  }

  .home-visit{
    border:none;
  }

}

