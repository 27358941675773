.add-to-cartnumerical {
    border-radius: var(--br-3xs);
    background-color: var(--color-mediumslateblue);
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: calc(14px + .8vw);
    width: 100%;
}

.add-to-cart {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    color: var(--color-white);
    margin-left: auto;
    min-width: 52px;
    width: 36%;
    height: fit-content;
    font-size: var(--font-size-13xl);
}

.add-to-cart-lab{
    height: 57px !important;
    padding-top: 17px !important;
}

.plus-symbol-child, .minus-symbol-child {
    position: relative;
    max-height: 100%;
    width: 17px;
    object-fit: cover;
    width: calc(6px + 0.5vw);
}

.plus-symbol {
    border-radius: var(--br-3xs);
    background-color: var(--color-royalblue);
    padding: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 41%;
    text-align: center;
  }

  .wrapper {
    width: calc(10px + 0.7vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }