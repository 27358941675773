.lifestyle-row{
    width: 44%;
    flex-wrap: wrap;
    border: 1px solid var(--backgroundquaternary);
    padding: 10px;
    gap: 59px;
    min-width: 190px;
}

.lifestyle-name{
    width: 30%;
    font-weight: 600;
}