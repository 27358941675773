.registration-flow {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.registration-flow .div {
  background-color: var(--backgroundprimary);
  height: 100vh;
  position: relative;
  width: 100%;
}

.registration-flow .overlap-group {
  height: 641px;
  width: 80%;
  margin: auto;
}

.registration-flow .doctor-star-instance {
  left: 63%;
  position: absolute !important;
  top: 86px !important;
}

.registration-flow .design-component-instance-node {
  height: 165px !important;
  left: 0 !important;
  top: 0 !important;
  transform: rotate(-90deg) !important;
  width: 165px !important;
}

.registration-flow .patient-star-instance {
  position: absolute !important;
  top: 86px !important;
  right: 60% ;
}

.registration-flow .patient-star-2 {
  height: 165px !important;
  left: 0 !important;
  top: 0 !important;
  transform: rotate(90deg) !important;
  width: 165px !important;
}

.registration-flow .doctor-health {
  width:100%;
}

.registration-flow .text-wrapper {
  color: var(--secondarymain);
  font-family: "Montserrat", Helvetica;
    font-size: calc(18.5px + 1.6vw);
  font-weight: 800;
  height: 59px;
  left: 486px;
  letter-spacing: 0;
  line-height: normal;
  top: 106px;
}

.registration-flow .text-wrapper-2 {
  color: var(--secondarymain);
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5px + 1.2vw);
  font-weight: 600;
  height: 49px;
  left: 623px;
  letter-spacing: 0;
  line-height: normal;
  top: 180px;
}

.registration-flow .logo {
  height: 172px;
  top: 35px;
  width: 258px;
  position: absolute;
  left: 60px
}


@media screen and (max-width: 650px) {
  .registration-flow .doctor-health {
    width: 90% !important;
  }

.text{
  width: fit-content;
}

  .registration-flow .design-component-instance-node , .registration-flow .patient-star-2{
    width:60px !important;
  }

  .registration-flow .text-wrapper , .registration-flow .text-wrapper-2{
    position: static;
    height: auto;
  }

  .welcome-div{
    flex-direction: column;
  }

  .registration-flow .patient-star-instance {
    right: auto
  }
  
  .registration-flow .logo{
    position: relative;
  }

}
