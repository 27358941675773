.checkout-card {
    display: inline-flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 24.203px;
    background: #FFF;
    box-shadow: 12px 12px 36px 6px rgba(2, 2, 70, 0.12);
    z-index: 3;
    right: 30px;
    font-family: 'Inter';
}

.sub-checkout-card {
    border-radius: 6.129px;
    border: 0.383px solid #B2BCCA;
    background: #FFF;
    display: flex;
    padding: 12.257px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.596px;
    align-self: stretch;
}

.checkout-top {
    border-bottom: 1.5px solid #E0E0E0;
}

.checkout-price-card {
    color: var(--Gray-500, #667085);
}

.promo-div {
    right: 30px;
    z-index: 2;
    flex-wrap: wrap;
    gap: 2%;
}

.card-checkout-container {
    width: 35%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.address-add-btn {
    border-radius: var(--br-81xl);
    background-color: var(--teritiarymain);
    margin: 0 10px;
}