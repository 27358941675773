.email-input {
  align-items: center;
  border: 1px solid;
  border-radius: 10px;
  display: flex;
  padding: 10px 20px;
  position: relative;
  width: 452px;
}

.email-input .div {
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5 + 0.23vw);
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.email-input.empty {
  border-color: var(--mid-gray);
}

.email-input.email {
  border-color: var(--labelprimary);
}

.email-input.empty .div {
  color: var(--mid-gray);
}

.email-input.email .div {
  color: var(--labelprimary);
}

.text-input{
  height: 43px;
  background: none !important;
}

.text-input::placeholder{
  font-size: 16px;
}

