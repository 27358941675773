.disabled-option{
    color: var(--backgroundquaternary);
    font-weight: 500;
}

@media screen and (max-width: 950px) {

    .filter-search-container {
        width: 90%;
        margin: auto;
        margin-top: 20px
    }

    .cards-doctors-div {
        width: 80%;
        margin: auto;
    }
    
}
