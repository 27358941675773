.pharmacy {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .doctor-bg{
    background-image: url("/public/img/copy-space-medical-workplace.png");
    background-size: 64% 100%;
    padding-top: 3rem;
    min-height: 66.8vh;
    background-position-x: right;
    background-repeat: no-repeat;
  }

  .doctor-img-info{
    border: 10px solid var(--navbar);
    padding: 5px;
    background-color: #fff;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 15px;
  }

  .doctor-div{
    text-align: -webkit-auto;
  }
  
  .pharmacy .div-5 {
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    height: 1500px;
    
  }
  
  .pharmacy .main-container {
    position: relative;
  }
  
  .pharmacy .pharmacy-slider-instance {
    z-index: 2 !important;
  }
  
  .pharmacy .pharmacy-slider-2 {
    height: 452px !important;
    top: -18px !important;
  }
  
  .pharmacy .pharmacy-slider-3 {
    height: 398px !important;
    top: 54px !important;
  }
  
  .pharmacy .pharmacy-slider-4 {
    height: 207px !important;
  }
  
  .pharmacy .medicine-categories {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 0 45px;
  }
  
  .pharmacy .text-wrapper-19 {
    color: #000000;
    font-family: "Montserrat", Helvetica;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .pharmacy .categories-placeholder {
    width: 326px !important;
  }
  
  .pharmacy .categories-instance {
    height: 33px !important;
    left: 293px !important;
    position: absolute !important;
    top: 95px !important;
    width: 33px !important;
  }
  
  .pharmacy .popular-products {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 70px;
    position: relative;
    z-index: 0;
  }
  
  .pharmacy .popular-products-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    position: relative;
  }
  
  .pharmacy .pharmacy-cards {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 30px;
    overflow-x: scroll;
    position: relative;
  }
  
  .pharmacy .design-component-instance-node-2 {
    flex: 0 0 auto !important;
  }
  
  .pharmacy .image-placeholder-instance {
    align-self: stretch !important;
    flex: 0 0 auto !important;
    height: unset !important;
    left: unset !important;
    position: relative !important;
    top: unset !important;
    width: 100% !important;
  }
  
  .pharmacy .navbar-search-bar {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
  }
  
  .pharmacy .navbar-instance {
    margin-top: unset !important;
  }
  
  .pharmacy .medicine-search-bar {
    -webkit-backdrop-filter: blur(30px) brightness(100%);
    align-items: flex-start;
    backdrop-filter: blur(30px) brightness(100%);
    background-color: #acd1ff80;
    border-color: #001e61;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    gap: 40px;
    height: 120px;
    justify-content: center;
    padding: 40px 31px;
    position: relative;
    width: 1441px;
  }
  
  .pharmacy .medicine-search-bar-2 {
    align-items: center;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #001e61;
    border-radius: 10px;
    border-right-style: solid;
    border-right-width: 1px;
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 20px;
    height: 44px;
    justify-content: center;
    margin-bottom: -4px;
    position: relative;
  }
  
  .pharmacy .text-placeholer {
    color: #757575;
    flex: 1;
    font-family: "Montserrat", Helvetica;
    font-size: 20px;
    font-weight: 400;
    height: 44px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
  }
  
  .pharmacy .search-button-instance {
    border: 1px solid !important;
    border-color: #001e61 !important;
    border-radius: 0px 10px 10px 0px !important;
    display: flex !important;
    height: 44px !important;
    width: 130px !important;
  }
  
  .pharmacy .search-button-2 {
    align-self: stretch !important;
    flex: 1 !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-top: -1px !important;
    width: unset !important;
  }
  
  .pharmacy .indicator {
    height: 1px;
    left: 723px;
    position: absolute;
    top: 93px;
    width: 105px;
  }
  
  .pharmacy .ask-a-doctor-instance {
    left: 1015px !important;
    position: fixed !important;
    top: 815px !important;
  }

  .pharmacy-cards{
    max-width: 100%;
    overflow: auto;
    padding-bottom: 20px;
  }

  .rectangle-2{
    color: var(--secondarymain);
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: calc(14px + .9vw);
    cursor: pointer;
  }

  .rectangle-2:hover{
    color: var(--secondarysecondshade);
  }

  .add-to-cart-btn{
    height: 3vw;
    margin-right: 5%;
    margin-left: 5%;
  }