.image-placeholder-icon-personal-card {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.089);
  }
  .wrapper-image-placeholder {
    width: 224px;
    height: 224px;
    position: relative;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .examination,
  .john-doe {
    margin: 0;
    position: relative;
    font-family: inherit;
    display: inline-block;
  }
  .john-doe {
    align-self: stretch;
    height: 44px;
    font-size: inherit;
    font-weight: 600;
  }
  .examination {
    height: 39px;
    font-size: var(--font-size-13xl);
    font-weight: 500;
    color: var(--labelprimary);
    text-align: left;
  }
  .nametitle {
    gap: 2px;
  }
  .namerating,
  .nameratingpic,
  .nametitle {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .nameratingpic {
    justify-content: flex-start;
    padding: var(--padding-3xs) 0;
    gap: var(--gap-mini);
  }
  .connect1,
  .view-profile {
    position: relative;
    font-weight: 500;
  }
  .view-profile {
    flex: 1;
    display: inline-block;
    min-width: 149px;
  }
  .call-icon,
  .chat-icon {
    height: 40px;
    width: 40px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 40px;
  }
  .connect-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  .connect,
  .contact-info-card {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-info-card {
    flex-direction: row;
    gap: var(--gap-mini);
    font-size: var(--font-size-5xl);
    color: var(--mid-gray);
  }
  .connect {
    flex-direction: column;
    font-size: var(--font-size-xl);
    color: var(--labelprimary);
  }
  .view-profile2 {
    position: relative;
    font-size: var(--font-size-5xl);
    font-family: var(--font-montserrat);
    color: var(--backgroundprimary);
    text-align: center;
  }
  .doctors-info,
  .view-profile1 {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  .view-profile1 {
    cursor: pointer;
    border: 0;
    padding: var(--padding-3xs) 15px;
    background-color: var(--primarymain);
    width: 340px;
    margin: 0 !important;
    position: absolute;
    bottom: 32px;
    left: 20px;
    border-radius: var(--br-3xs);
    flex-direction: row;
    justify-content: center;
    white-space: nowrap;
  }
  .view-profile1:hover {
    background-color: #298cff;
  }
  .doctors-info {
    width: 380px;
    border-radius: var(--br-3xs) 0 0 var(--br-3xs);
    background-color: var(--backgroundsecondary);
    box-shadow: 8px 0 15px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    justify-content: flex-start;
    padding: var(--padding-xl);
    position: relative;
    gap: var(--gap-mini);
    min-width: 380px;
    min-height: 470px;
    max-width: 100%;
    margin: 0 38px;
  }
  .booking-details1 {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 700;
    font-family: inherit;
  }
  .booking-info-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-xl) 0 0;
  }
  .close-icon {
    height: 30px;
    width: 30px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .booking-info-frame-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-xl);
  }
  .session-time1 {
    height: 29px;
    font-weight: 600;
    display: inline-block;
  }
  .pm-700pm,
  .session-time1 {
    position: relative;
  }
  .access-time-icon {
    height: 30px;
    width: 30px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .starts-in-5h {
    position: relative;
    font-weight: 600;
  }
  .starts-in {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
    text-align: left;
    color: var(--primarymain);
  }
  .session-time,
  .time {
    display: flex;
    justify-content: center;
  }
  .time {
    flex-direction: row;
    align-items: center;
    gap: 25px;
    text-align: center;
    font-size: var(--font-size-xl);
  }
  .session-time {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-8xs);
    max-width: 100%;
  }
  .separator {
    align-self: stretch;
    height: 1px;
    position: relative;
    border-top: 1px solid var(--backgroundquaternary);
    box-sizing: border-box;
  }
  .booked-on1 {
    height: 29px;
    font-weight: 600;
    display: inline-block;
  }
  .booked-on1,
  .th-of-may {
    position: relative;
  }
  .datetime {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-xl);
  }
  .booked-on {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .separator1 {
    align-self: stretch;
    height: 1px;
    position: relative;
    border-top: 1px solid var(--backgroundquaternary);
    box-sizing: border-box;
  }
  .appointment-type1 {
    position: relative;
    font-weight: 600;
  }
  .examination1 {
    position: relative;
  }
  .type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-xl);
  }
  .appointment-type {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .separator2 {
    align-self: stretch;
    height: 1px;
    position: relative;
    border-top: 1px solid var(--backgroundquaternary);
    box-sizing: border-box;
  }
  .cost {
    position: relative;
    display: inline-block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: -webkit-auto;
  }
  .cost,
  .span {
    font-weight: 600;
  }
  .div {
    position: relative;
    white-space: nowrap;
  }
  .type1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-xl);
  }
  .appointment-type2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .separator3 {
    align-self: stretch;
    height: 1px;
    position: relative;
    border-top: 1px solid var(--backgroundquaternary);
    box-sizing: border-box;
  }
  .discount-applied {
    position: relative;
    font-weight: 600;
  }
  .off-dermatology {
    position: relative;
  }
  .type2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--font-size-xl);
  }
  .appointment-type3,
  .session-time-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .session-time-parent {
    flex: 1;
    gap: var(--gap-xl);
    max-width: 100%;
  }
  .appointment-details,
  .booking-details {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  .appointment-details {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 33px 0 0;
    font-size: var(--font-size-5xl);
  }
  .booking-details {
    flex: 1;
    border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
    background-color: var(--backgroundprimary);
    flex-direction: column;
    align-items: center;
    padding: var(--padding-3xs) var(--padding-3xs) 139px 40px;
    gap: 30px;
    min-width: 304px;
    text-align: left;
    font-size: var(--font-size-13xl);
    color: var(--labelprimary);
  }
  .patient-details-layout {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 20px;
    letter-spacing: normal;
    text-align: center;
    font-size: 36px;
    color: var(--secondarymain);
    font-family: var(--font-montserrat);
  }
  .upload-img-icon{
    width: 40px;
    position: absolute;
    top: 18px;
    right: 19px;
  }
  @media screen and (max-width: 650px) {
    .john-doe {
      font-size: 29px;
    }
    .examination {
      font-size: var(--font-size-7xl);
    }
    .doctors-info {
      flex: 1;
    }
    .booking-details1 {
      font-size: var(--font-size-7xl);
    }
    .patient-details-layout {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 675px) {
    .booking-details {
      gap: var(--gap-mini);
      padding-top: var(--padding-xl);
      padding-bottom: 90px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .john-doe {
      font-size: 22px;
    }
    .examination {
      font-size: var(--font-size-lgi);
    }
    .connect1 {
      font-size: var(--font-size-base);
    }
    .view-profile {
      font-size: var(--font-size-lgi);
    }
    .contact-info-card {
      flex-wrap: wrap;
    }
    .doctors-info {
      min-width: 100%;
    }
    .booking-details1 {
      font-size: var(--font-size-lgi);
    }
    .booking-info-frame-parent {
      flex-wrap: wrap;
    }
    .session-time1 {
      font-size: var(--font-size-lgi);
    }
    .pm-700pm,
    .starts-in-5h {
      font-size: var(--font-size-base);
    }
    .time {
      flex-wrap: wrap;
    }
    .booked-on1 {
      font-size: var(--font-size-lgi);
    }
    .th-of-may {
      font-size: var(--font-size-base);
    }
    .appointment-type1 {
      font-size: var(--font-size-lgi);
    }
    .examination1 {
      font-size: var(--font-size-base);
    }
    .cost {
      font-size: var(--font-size-lgi);
    }
    .div {
      font-size: var(--font-size-base);
    }
    .discount-applied {
      font-size: var(--font-size-lgi);
    }
    .off-dermatology {
      font-size: var(--font-size-base);
    }
  }