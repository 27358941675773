.dashboard-patients-overview .hi-john-doe {
  position: relative;
  font-weight: 500;
}

.dashboard-patients-overview .welcome-back {
  position: relative;
  font-size: calc(18px + 1.1vw);
  color: var(--labelprimary);
}

.dashboard-patients-overview .welcome-message {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-8xs);
  z-index: 0;
}

.notifications-icon {
  width: calc(1vw + 26px);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;

}

.dashboard-patients-overview .welcome-banner {
  position: relative;
  left: 2px;
  top: 0;
  background-color: var(--backgroundsecondary);
  border-bottom: 1px solid var(--backgroundquaternary);
  box-sizing: border-box;
  width: 99.8%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-11xl);
  color: var(--secondarymain);
  height: 124px;
}

.dashboard-patients-overview .the-home-nursing {
  position: relative;
  font-size: var(--font-size-5xl);
  font-weight: 600;
  color: var(--secondarymain);
}

.dashboard-patients-overview .get-the-medical {
  margin: 0;
}

.dashboard-patients-overview .get-the-medical-container {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.01em;
}

.dashboard-patients-overview .tov {
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  z-index: 0;
}

.dashboard-patients-overview .book-now {
  position: relative;
}

.dashboard-patients-overview .cta {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs);
  z-index: 1;
  font-size: var(--font-size-xl);
  color: var(--secondarymain);
}

.dashboard-patients-overview .cutout-1-icon {
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 0;
  border-radius: 0 var(--br-3xs) var(--br-3xs) 0;
  width: 286px;
  height: 286px;
  object-fit: cover;
  z-index: 2;
}

.dashboard-patients-overview .tov1 {
  border-radius: var(--br-3xs);
  /* width: 338.98px; */
  gap: var(--gap-8xs);
  z-index: 0;
}

.dashboard-patients-overview .woman-holding-blood-sample-mic-icon {
  position: absolute;
  margin: 0 !important;
  right: 127px;
  bottom: 0;
  width: 276px;
  height: 276px;
  object-fit: cover;
  z-index: 2;
}


.dashboard-patients-overview .tov2 {
  /* width: 338.dashboard-patients-overview .98px; */
  gap: var(--gap-8xs);
  z-index: 0;
}

.dashboard-patients-overview .patient-with-doctor-at-home-cu-icon {
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 0;
  border-radius: 0 0 var(--br-3xs) 0;
  width: 459px;
  height: 306px;
  object-fit: cover;
  z-index: 2;
}

.dashboard-patients-overview .teleconsultation-cutout-1 {
  position: absolute;
  margin: 0 !important;
  right: 125px;
  bottom: 0;
  width: 266px;
  height: 266px;
  object-fit: cover;
  z-index: 2;
}


.dashboard-patients-overview .div,
.dashboard-patients-overview .div1 {
  position: relative;
  border-radius: 50%;
  background-color: var(--teritiarymain);
  width: 20px;
  height: 20px;
}

.dashboard-patients-overview .div1 {
  background-color: var(--backgroundprimary);
  opacity: 0.35;
}

.dashboard-patients-overview .slider-spheres-5 {
  position: absolute;
  bottom: 7px;
  left: calc(50% - 100px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xl);
}

.dashboard-patients-overview .feature-banner-carousel {
  position: relative;
  font-size: var(--font-size-base);
}

.react-slideshow-wrapper.slide {
  width: 100%;
  margin: auto;
  overflow: hidden;
  height: 398px;
}

.dashboard-patients-overview .icon-child {
  position: relative;
  border-radius: 50%;
  background-color: var(--primarysecondshade);
  width: 78px;
  height: 78px;
  z-index: 0;
}

.dashboard-patients-overview .calendar-today-icon {
  position: absolute;
  margin: 0 !important;
  top: 19px;
  left: 19px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}

.dashboard-patients-overview .b,
.dashboard-patients-overview .icon {
  position: relative;
  display: flex;
  flex-shrink: 0;
}

.dashboard-patients-overview .icon {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .b {
  align-self: stretch;
  align-items: center;
  height: 24px;
}

.dashboard-patients-overview .total-bookings-txt-container {
  line-break: anywhere;
}

.dashboard-patients-overview .total-bookings {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  color: var(--secondarymain);
  display: flex;
  align-items: center;
}

.dashboard-patients-overview .bookings-summary,
.dashboard-patients-overview .numbertitle {
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .numbertitle {
  height: 78px;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-4xs) 0;
}

.dashboard-patients-overview .bookings-summary {
  border-radius: var(--br-61xl);
  width: 230px;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}

.dashboard-patients-overview .icon-item {
  position: relative;
  border-radius: 50%;
  background-color: var(--positiveactionteritiary);
  width: 78px;
  height: 78px;
  z-index: 0;
}

.dashboard-patients-overview .verified-icon {
  position: absolute;
  margin: 0 !important;
  top: 35px;
  left: 31px;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
}

.dashboard-patients-overview .numbertitle1 {
  flex: 1;
  height: 78px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-4xs) 0;
  box-sizing: border-box;
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .icon-inner {
  position: relative;
  border-radius: 50%;
  background-color: var(--negativeactionquaternary);
  width: 78px;
  height: 78px;
  z-index: 0;
}

.dashboard-patients-overview .cancel-icon {
  position: absolute;
  margin: 0 !important;
  top: 36px;
  left: 32px;
  width: 14px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
}

.dashboard-patients-overview .booking-summary {
  position: absolute;
  top: 407px;
  left: calc(50% - 339px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 35px;
  font-size: var(--font-size-13xl);
}

.dashboard-patients-overview .june-2023 {
  position: relative;
  font-size: var(--font-size-xl);
  font-weight: 600;
}

.dashboard-patients-overview .date-year {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}

.dashboard-patients-overview .arrow-movement-circle {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.515);
}

.dashboard-patients-overview .wrapper-arrow-movement-circle {
  opacity: 0.3;
}

.dashboard-patients-overview .wrapper-arrow-movement-circle,
.dashboard-patients-overview .wrapper-arrow-movement-circle1 {
  position: relative;
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-patients-overview .date-titlearrows,
.dashboard-patients-overview .nav-arrows {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xl);
}

.dashboard-patients-overview .date-titlearrows {
  align-items: center;
  padding: var(--padding-3xs) var(--padding-mini);
  gap: var(--gap-132xl);
  z-index: 2;
}

.dashboard-patients-overview .numerical {
  border-radius: var(--br-61xl);
  width: 26px;
  height: 26px;
  padding: var(--padding-10xs) var(--padding-8xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
}

.dashboard-patients-overview .div5,
.dashboard-patients-overview .numerical,
.dashboard-patients-overview .numerical1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-patients-overview .div5 {
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: var(--br-61xl);
  background-color: var(--primarymain);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
  color: var(--backgroundprimary);
}

.dashboard-patients-overview .numerical1 {
  width: 26px;
  height: 26px;
  padding: var(--padding-10xs);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--labelprimary);
}

.dashboard-patients-overview .div11,
.dashboard-patients-overview .div13,
.dashboard-patients-overview .div15,
.dashboard-patients-overview .div17,
.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25,
.dashboard-patients-overview .div7,
.dashboard-patients-overview .div9 {
  position: absolute;
  top: 5px;
  left: 55px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .div11,
.dashboard-patients-overview .div13,
.dashboard-patients-overview .div15,
.dashboard-patients-overview .div17,
.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25,
.dashboard-patients-overview .div9 {
  left: 107px;
}

.dashboard-patients-overview .div11,
.dashboard-patients-overview .div13,
.dashboard-patients-overview .div15,
.dashboard-patients-overview .div17,
.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 157px;
}

.dashboard-patients-overview .div13,
.dashboard-patients-overview .div15,
.dashboard-patients-overview .div17,
.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 210px;
}

.dashboard-patients-overview .div15,
.dashboard-patients-overview .div17,
.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 260px;
}

.dashboard-patients-overview .div17,
.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 310px;
}

.dashboard-patients-overview .div19,
.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 360px;
}

.dashboard-patients-overview .div21,
.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 410px;
}

.dashboard-patients-overview .div23,
.dashboard-patients-overview .div25 {
  left: 462px;
}

.dashboard-patients-overview .div25 {
  left: 512px;
}

.dashboard-patients-overview .div27,
.dashboard-patients-overview .div29,
.dashboard-patients-overview .div31,
.dashboard-patients-overview .div33,
.dashboard-patients-overview .div35,
.dashboard-patients-overview .div37,
.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  position: absolute;
  top: 5px;
  left: 565px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-8xs);
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .div29,
.dashboard-patients-overview .div31,
.dashboard-patients-overview .div33,
.dashboard-patients-overview .div35,
.dashboard-patients-overview .div37,
.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 615px;
}

.dashboard-patients-overview .div31,
.dashboard-patients-overview .div33,
.dashboard-patients-overview .div35,
.dashboard-patients-overview .div37,
.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 665px;
}

.dashboard-patients-overview .div33,
.dashboard-patients-overview .div35,
.dashboard-patients-overview .div37,
.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 715px;
}

.dashboard-patients-overview .div35,
.dashboard-patients-overview .div37,
.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 765px;
}

.dashboard-patients-overview .div37,
.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 817px;
}

.dashboard-patients-overview .div39,
.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 867px;
}

.dashboard-patients-overview .div41,
.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 920px;
}

.dashboard-patients-overview .div43,
.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 970px;
}

.dashboard-patients-overview .div45,
.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1020px;
}

.dashboard-patients-overview .div47,
.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1070px;
}

.dashboard-patients-overview .div49,
.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1120px;
}

.dashboard-patients-overview .div51,
.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1172px;
}

.dashboard-patients-overview .div53,
.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1222px;
}

.dashboard-patients-overview .div55,
.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1275px;
}

.dashboard-patients-overview .div57,
.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1325px;
}

.dashboard-patients-overview .div59,
.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1375px;
}

.dashboard-patients-overview .div61,
.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1425px;
}

.dashboard-patients-overview .div63,
.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1475px;
}

.dashboard-patients-overview .next,
.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1527px;
}

.dashboard-patients-overview .next1,
.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1577px;
}

.dashboard-patients-overview .next2,
.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1630px;
}

.dashboard-patients-overview .next3,
.dashboard-patients-overview .next4 {
  left: 1680px;
}

.dashboard-patients-overview .next4 {
  left: 1730px;
}

.dashboard-patients-overview .date-carousel {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  height: 67px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
  font-size: var(--font-size-xs);
  color: var(--mid-gray);
}

.dashboard-patients-overview .div70 {
  position: relative;
  font-size: var(--font-size-17xl);
  font-weight: 600;
}

.dashboard-patients-overview .date-square {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundsecondary);
  width: 72px;
  height: 69px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-6xl);
  box-sizing: border-box;
}

.dashboard-patients-overview .dermatologist,
.dashboard-patients-overview .dr-hamdy-maged {
  position: relative;
  font-weight: 600;
}

.dashboard-patients-overview .dermatologist {
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--mid-gray);
}

.dashboard-patients-overview .specialist-details,
.dashboard-patients-overview .time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dashboard-patients-overview .time {
  border-radius: var(--br-10xs);
  background-color: var(--primarymain);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) var(--padding-7xs);
  font-size: var(--font-size-sm);
  color: var(--backgroundprimary);
}

.dashboard-patients-overview .nametime {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  font-size: var(--font-size-xl);
}

.dashboard-patients-overview .keyboard-arrow-right-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.dashboard-patients-overview .upcoming-appointment-mini-card,
.dashboard-patients-overview .upcoming-appointment-mini-card1,
.dashboard-patients-overview .upcoming-appointment-mini-card2 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  height: 92px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  z-index: 2;
}

.dashboard-patients-overview .upcoming-appointment-mini-card1,
.dashboard-patients-overview .upcoming-appointment-mini-card2 {
  z-index: 1;
}

.dashboard-patients-overview .upcoming-appointment-mini-card2 {
  z-index: 0;
}

.dashboard-patients-overview .upcoming-appointments,
.dashboard-patients-overview .upcoming-appointments-patients {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dashboard-patients-overview .upcoming-appointments {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-8xs);
  min-height: 286px;
  z-index: 0;
  font-size: var(--font-size-sm);
}

.dashboard-patients-overview .upcoming-appointments-patients {
  position: absolute;
  right: 32px;
  bottom: 22px;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundsecondary);
  align-items: center;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  min-height: 468px;
  font-size: var(--font-size-base);
}

.dashboard-patients-overview .available-specialists {
  position: relative;
  font-weight: 600;
  z-index: 0;
}

.dashboard-patients-overview .image-placeholder-icon {
  position: relative;
  border-radius: 50%;
  width: 68px;
  height: 68px;
  object-fit: cover;
}

.dashboard-patients-overview .specialist-details3 {
  align-self: stretch;
  width: 202px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dashboard-patients-overview .available-doctor {
  left: 5px;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  flex-shrink: 0;
}

.dashboard-patients-overview .available-doctor,
.dashboard-patients-overview .available-doctor1,
.dashboard-patients-overview .available-doctor2 {
  position: absolute;
  top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-8xs);
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .available-doctor1 {
  left: 305px;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  flex-shrink: 0;
}

.dashboard-patients-overview .available-doctor2 {
  left: 605px;
}

.dashboard-patients-overview .available-doctor2,
.dashboard-patients-overview .available-doctor3,
.dashboard-patients-overview .carousel-strip {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  flex-shrink: 0;
}

.dashboard-patients-overview .available-doctor3 {
  position: absolute;
  top: 6px;
  left: 905px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-8xs);
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .carousel-strip {
  position: relative;
  width: 600px;
  height: 98px;
  overflow: hidden;
  z-index: 1;
  font-size: var(--font-size-xl);
  color: var(--labelprimary);
}

.dashboard-patients-overview .nav-arrows1 {
  margin: 0 !important;
  position: absolute;
  top: 15px;
  right: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xl);
  z-index: 2;
}

.dashboard-patients-overview .available-specialsts {
  position: absolute;
  top: 534px;
  left: 376px;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundsecondary);
  width: 633px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  color: var(--secondarymain);
}

.dashboard-patients-overview .title {
  align-self: stretch;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}

.dashboard-patients-overview .column-title,
.dashboard-patients-overview .placeholder,
.dashboard-patients-overview .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-patients-overview .column-title {
  align-self: stretch;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 0 0 var(--padding-3xs);
}

.dashboard-patients-overview .placeholder {
  justify-content: center;
}

.dashboard-patients-overview .column-prescribed-by,
.dashboard-patients-overview .prescribed-by {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.dashboard-patients-overview .prescribed-by {
  align-self: stretch;
  gap: var(--gap-mini);
  font-size: var(--font-size-3xs);
}

.dashboard-patients-overview .column-prescribed-by {
  flex-shrink: 0;
  gap: var(--gap-8xs);
}

.dashboard-patients-overview .column-title2 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 var(--padding-3xs);
}

.dashboard-patients-overview .details,
.dashboard-patients-overview .details-columns,
.dashboard-patients-overview .prescriptions-summary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-patients-overview .details-columns {
  flex-direction: row;
  gap: var(--gap-mini);
}

.dashboard-patients-overview .details,
.dashboard-patients-overview .prescriptions-summary {
  border-radius: var(--br-3xs);
  flex-direction: column;
}

.dashboard-patients-overview .details {
  align-self: stretch;
  background-color: var(--backgroundprimary);
  padding: var(--padding-3xs);
  font-size: var(--font-size-xs);
  color: var(--labelprimary);
}

.dashboard-patients-overview .prescriptions-summary {
  position: absolute;
  bottom: 22px;
  left: 376px;
  background-color: var(--backgroundsecondary);
  overflow: hidden;
  padding: 0 var(--padding-3xs) var(--padding-mini);
  box-sizing: border-box;
  min-width: 633px;
  max-width: 633px;
  color: var(--secondarymain);
}

.dashboard-patients-overview .title-child {
  position: relative;
  width: 121px;
  height: 59px;
  object-fit: cover;
}

.dashboard-patients-overview .for-patients {
  position: relative;
  font-size: var(--font-size-base);
}

.dashboard-patients-overview .dashboard-parent {
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-8xs);
}

.dashboard-patients-overview .dashboard-parent,
.dashboard-patients-overview .title1,
.dashboard-patients-overview .title2 {
  display: flex;
  align-items: flex-start;
}

.dashboard-patients-overview .title2 {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .title1 {
  align-self: stretch;
  background-color: var(--backgroundsecondary);
  border-right: 1px solid var(--backgroundquaternary);
  border-bottom: 1px solid var(--backgroundquaternary);
  box-sizing: border-box;
  height: 124px;
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-21xl) var(--padding-11xl);
  z-index: 0;
}

.dashboard-patients-overview .preview-icon {
  position: relative;
  width: 29px;
  height: 29px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.dashboard-patients-overview .overview {
  align-self: stretch;
  border-radius: var(--br-3xs) 0 0 var(--br-3xs);
  background-color: var(--secondaryfirstshade);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.dashboard-patients-overview .25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  gap: var(--gap-3xs);
  color: var(--backgroundprimary);
}

.dashboard-patients-overview .pending-actions-icon {
  position: relative;
  width: 29px;
  height: 29px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  mix-blend-mode: normal;
}

.dashboard-patients-overview .booking-activity {
  align-self: stretch;
  border-radius: var(--br-3xs) 0 0 var(--br-3xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  gap: var(--gap-3xs);
}

.dashboard-patients-overview .main-menu,
.dashboard-patients-overview .supportreturn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dashboard-patients-overview .main-menu {
  align-self: stretch;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-xl) var(--padding-xl);
  gap: var(--gap-8xs);
  z-index: 1;
}

.dashboard-patients-overview .supportreturn {
  background-color: var(--backgroundsecondary);
  border-top: 1px solid var(--backgroundquaternary);
  border-right: 1px solid var(--backgroundquaternary);
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  justify-content: flex-end;
  padding: var(--padding-21xl) 0 var(--padding-21xl) var(--padding-33xl);
  z-index: 2;
  margin-top: auto;
}

.dashboard-patients-overview .dashboard-patients-overview,
.dashboard-patients-overview .full-side-menu {
  background-color: var(--backgroundprimary);
  overflow: hidden;
}

.dashboard-patients-overview .full-side-menu {
  position: fixed;
  box-shadow: 4px 4px 30px 1px rgba(0, 0, 0, 0.25);
  border-right: 1px solid var(--backgroundquaternary);
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 25%;
}

.dashboard-patients-overview .dashboard-patients-overview {
  position: relative;
  width: 100%;
  height: 1024px;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--labelprimary);
  font-family: var(--font-montserrat);
}

.dashboard-container {
  display: flex;
  width: 75%;
  position: relative;
  flex-direction: column;
}

.dashboard-home-container{
  overflow: auto;
  height: 80vh;
  padding-bottom: 30px;
}

.return-home-dash {
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  margin: auto;
}























.questions-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-circle-outline-icon {
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.new-question {
    border-radius: var(--br-81xl);
    background-color: var(--teritiarymain);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    width: 73px;
    height: 73px;
}

.add-address-btn{
  position: absolute;
  top: 32px;
}

.left88per{
  left: 88%;
}

.right88per{
  right: 88%;
}

.dashboard-patients-medical-qu {
  position: relative;
  background-color: var(--backgroundprimary);
  width: 100%;
  /* height: 1024px; */
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--secondarymain);
  font-family: var(--font-montserrat);
}

.even-though-i {
  flex: 1;
  position: relative;
  font-weight: 500;
  white-space: pre-wrap;
}

.input-field-dashboard {
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  background-color: var(--primarysecondshade);
  border: 1px solid var(--secondarymain);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
}

.pm-thu-6123 {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.datetime {
  width: 271px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-mini);
  box-sizing: border-box;
}

.delete-icon {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.deledit,
.delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-8xs);
}

.deledit {
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-mini);
  box-sizing: border-box;
  gap: var(--gap-8xs);
  min-height: 70px;
  max-height: 70px;
}

.check-circle-icon {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.actions,
.response-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.response-indicator {
  padding: var(--padding-mini);
  gap: var(--gap-8xs);
  font-size: var(--font-size-xl);
  color: var(--positiveactionprimary);
}

.actions {
  font-size: var(--font-size-sm);
  color: var(--primarymain);
  flex-wrap: wrap;
}

.actionsdate,
.question-box {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.actionsdate {
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  border-right: 1px solid var(--secondarymain);
  border-bottom: 1px solid var(--secondarymain);
  border-left: 1px solid var(--secondarymain);
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  gap: var(--gap-381xl);
  color: var(--mid-gray);
}

.question-box {
  flex-direction: column;
  justify-content: center;
  padding: var(--padding-3xs) 0;
  min-height: fit-content;
}

.width90per {
  width: 90%;
}

.response-indicator1,
.response-indicator2 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini);
  gap: var(--gap-8xs);
  font-size: var(--font-size-xl);
  color: var(--positiveactionprimary);
}

.response-indicator1 {
  display: none;
}

.response-indicator2 {
  display: flex;
  cursor: pointer;
}

.page-dashboard-container {
  height: 80vh;
  overflow: auto;
}

















.arrow-back-icon-dash {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -44px;
  top: 2px;
}

.even-though-i,
.pm-thu-6123 {
  position: relative;
  font-weight: 500;
}

.even-though-i {
  align-self: stretch;
  white-space: pre-wrap;
}

.pm-thu-6123 {
  font-size: var(--font-size-xs);
  color: var(--mid-gray);
}

.input-field,
.question-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.input-field-dashboard-div {
  border-radius: var(--br-8xs);
  background-color: var(--primarysecondshade);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-8xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  z-index: 0;
}

.question-box {
  align-items: center;
  min-width: 200px;
  width:80%
}

.doctor-iconname-child {
  position: relative;
  border-radius: 50%;
  width: 77px;
  height: 77px;
  object-fit: cover;
}

.dr-raji-fadel {
  position: relative;
  font-weight: 500;
}

.family-medicine {
  margin: 0;
}

.family-medicine-consultant-container {
  position: relative;
  font-size: var(--font-size-xs);
  text-align: center;
}

.doctor-iconname {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.hello-john-there-container {
  align-self: stretch;
  position: relative;
  font-weight: 500;
}

.input-field1,
.response-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.input-field1 {
  border-radius: var(--br-8xs);
  background-color: var(--positiveactionteritiary);
  overflow: hidden;
  align-items: flex-start;
  padding: var(--padding-8xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  z-index: 0;
}

.response-box {
  align-items: center;
  color: var(--secondarymain);
}

.doctor-iconname-parent,
.question-box-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.doctor-iconname-parent {
  flex-direction: row;
  gap: 15px;
  color: var(--labelprimary);
  width:80%
}

.question-box-parent {
  flex-direction: column;
  position: relative;
  font-size: var(--font-size-base);
}

.dashboard-patients-medical-qu {
  position: relative;
  background-color: var(--backgroundprimary);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--secondarymain);
  font-family: var(--font-montserrat);
}

.answered-p {
  font-size: calc(10px + 0.6vw);
}


.booking-card {
  position: relative;
  top: 61px;
  border-radius: var(--br-3xs);
  background-color: var(--backgroundsecondary);
  width: 330px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini);
  box-sizing: border-box;
  display: flex;
}

.booking-card .specialist-details {
  flex: 1;
  background-color: var(--labelsecondary);
  flex-direction: column;
  padding: var(--padding-mini);
  gap: var(--gap-3xs);
  border-radius: var(--br-3xs);

}

.dashboard-patients-booking-ac {
  position: relative;
  background-color: var(--labelsecondary);
  width: 100%;
  height: 1024px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--secondarymain);
  font-family: var(--font-montserrat);
}

.nametitlepic {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: left;
}

.booking-card-time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
  color: var(--labelprimary);
  width: 100%;
}

.dermatologist,
.dr-hamdy-maged {
  align-self: stretch;
  position: relative;
}

.dermatologist {
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--color-dimgray);
}

.nametitle {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-8xs);
}

.nametitlepic {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: left;
}

.pm-700pm {
  flex: 1;
  position: relative;
  font-weight: 500;
}

.access-time-icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.starts-in-5h {
  flex: 1;
  position: relative;
  font-weight: 600;
}

.starts-in {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  text-align: left;
  color: var(--primarymain);
}

.contact-info {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-mini);
  font-size: var(--font-size-base);
  color: var(--color-dimgray);
  width: 92%;
  align-items: center;
  justify-content: center;
  border-top: 1px var(--backgroundteritiary) solid;
  border-bottom: 1px var(--backgroundteritiary) solid;
  padding: 10px 0;
  height: 200px
}

.edit-icon{
  width: 39px;
}

.connect-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-mini);
}

.call-icon {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
}

.booking-card-details-btn {
  align-self: stretch;
  background-color: var(--primarymain);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-mini);
  color: var(--labelsecondary);
  border-radius: var(--br-3xs);
}

.left25per{
  left:25%;
}

.right25per{
  right:25%
}

.left75px{
  left:75px !important
}

.rectangle-arrow-ar{
  top: -1px !important;
  left: -237px !important;
  right: auto !important;
}

.notification-div-ar{
  left: 33px !important;
  top: 29px !important;
}

.notification-div-en{
  right: 40px !important;
  top: 55px !important;
}

.height86vh{
  height: 86vh;
}

ul{
  padding-left: 2rem;
  padding-right: 2rem;
}

.scrolled-down{
  flex-direction: column-reverse;
  display: flex;
}

.send-icon{
  top: 0px;
  width: 40px;
  right: -45px;
}

.medical-question-attachment{
  width:200px;
  margin-right:10%;
  margin-left:5%;
  margin-top:20px;
}

.head-family-table{
  background-color: #001e61;
  color: white;
  font-weight: 600;
}