.doctor-star {
  display: inline-flex;
  position: relative;
}

.doctor-star .class {
  height: 165.29px !important;
  width: 165.29px !important;
}

.doctor-star .class-2 {
  height: 165.29px !important;
  transform: rotate(90deg) !important;
  width: 165.29px !important;
}

.doctor-star .class-3 {
  color: var(--labelquaternary) !important;
}

.doctor-star.frame-13 {
  align-items: center;
}

.doctor-star.frame-12 {
  align-items: flex-start;
  justify-content: flex-end;
}

.doctor-star{
  gap: 25px;
}