.google-map-button {
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    padding: 3px 10px;
    font-family: Roboto, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    outline: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 58px;
    top: 10px;
  }
  
  .google-map-button img {
    margin-right: 5px;
    width: 20px;
  }