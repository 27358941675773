.settings-tab {
    border-bottom: 1px solid var(--mid-gray);
    width: fit-content;
    flex-wrap: wrap;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 51px;
}

.settings-li {
    color: var(--Mid-Gray, #7E7E7E);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px 0;
}

.settings-input{
    width: fit-content;
    display: flex;
    flex-direction: column;
    width: 500px;
}

.settings-input-label{
    color: var(--Mid-Gray, #7E7E7E);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: justify;
    width: fit-content;
}

.settings-inputs-container{
    width:90%
}

.selected-setting{
    color: var(--primarymain);
    font-weight: bold;
    border-bottom: 1px solid var(--primarymain);
}

.settings-input .dropdown-container{
    width: 100%;
    padding: 0;
    border: none !important
}

.settings-input .dropdown-container:focus, .settings-input .dropdown-container:focus{
    border: none !important;
    outline: none;
}

.settings-input .rmsc{
    padding: 4px 0;
}

.select-padding{
    padding: 2px 0 !important;
}

.multi-add-img{
    position: relative;
    width: 40px;
    height: 40px;
    top:0;
    right: 0;
}

.close-icon-multi{
    position: absolute;
    right: 0px;
    top: -16px;
    width: 14px;
    cursor: pointer;
}