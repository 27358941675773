@media screen and (max-width: 950px) {
    .frame {
        position: relative !important;
        flex-wrap: wrap !important;
        height: fit-content !important;
    }

    .feature-banner {
        height: fit-content !important;
    }
}

.book-now-btn {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    height: 33px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #001E61;
    position: absolute;
    flex: none;
    order: 0;
    flex-grow: 0;
    top: 147px;
    left: 31px;
}