.cart-circle-number{
    right: -5px;
    width: 18px;
    height: 18px;
    /* padding: 5px; */
    background-color: var(--teritiarymain);
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    top: -5px;
    color: white;
    border-radius: 100px;
}