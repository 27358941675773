.doctor_nam {
    font-size: calc(18px + 0.3vw);
    font-family: "Montserrat";
    font-weight: 800;
    color: rgba(0, 30, 97, 1);
    border: var(--secondarymain) solid 2px;
    border-radius: 10px;
    margin: auto;
    background-color: var(--backgroundprimary);
    padding: 10px;
}

.select_tim {
    font-size: calc(16px + 0.2vw);
    font-family: "Montserrat";
    font-weight: 700;
    color: rgba(3, 102, 254, 1);
}

.date {
    font-size: calc(16px + 0.2vw);
    font-family: "Montserrat";
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    margin-left: auto;
}

.fit-content{
    width: fit-content;
}

.time-slot{
    border: 2px var(--secondarymain) solid;
    border-radius: 10px;
    font-size: calc(10px + .4vw);
    padding: 10px 30px;
    font-weight: 600;
    color: var(--secondarymain);
    cursor: pointer;
}

.time-slots-container{
    gap:20px;
    background-color: var(--backgroundprimary);
    padding: 20px 15px;
    border-radius: 10px;
}

.book-btn-modal{
    font-size: calc(18px + 0.75vw);
    width: -webkit-fill-available;
}

.close-img{
    position: absolute;
    right: -4px;
    top: -30px;
    width: 21px;
    cursor: pointer;
}

.modal-checkbox-label{
    font-weight: 600;
    color: var(--secondarymain);
    font-size: 18px;
}

.react-calendar{
    position: absolute;
    width: 282px !important;
}

.selected-time-slot{
    background-color: var(--secondarymain);
    color: white;
}

.form-check-input{
    width: 1em !important;
}

.about-icon{
    height: 25px;
}