.categories {
  overflow: auto;
  height: fit-content;
  display: flex;
  gap: 25px;
  max-width: 100%;
  padding-bottom: 25px;
}

.categories .rectangle {
  background-color: var(--pastelsminttulip);
  border-radius: 10px;
  height: 224px;
  position: absolute;
  top: 0;
  width: 309px;
}

.categories .rectangle-2 {
  background-color: var(--pastelssail);
  border-radius: 10px;
  height: 224px;
  top: 0;
  width: 309px;
  min-width: 240px; 
  height: 289px;
  flex-direction: column;
}

.categories .rectangle-3 {
  background-color: var(--pastelsmanhattan);
  border-radius: 10px;
  height: 224px;
  position: absolute;
  top: 0;
  width: 309px;
}

.categories .overlap-group-3 {
  height: 224px;
  position: absolute;
  top: 0;
}

.categories .rectangle-4 {
  background-color: var(--pastelslightorchid);
  border-radius: 10px;
  height: 224px;
  left: 0;
  position: absolute;
  top: 0;
  width: 309px;
}

.categories .rectangle-5 {
  height: 224px;
  position: absolute;
  top: 0;
}

.categories .rectangle-6 {
  background-color: var(--pastelsmoonraker);
  border-radius: 10px;
  height: 224px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 309px;
}

.categories .arrow-movement {
  height: 50px !important;
  left: 0 !important;
  position: absolute !important;
  top: 87px !important;
  width: 50px !important;
}

.categories .rectangle-7 {
  background-color: var(--pastelssprout);
  border-radius: 10px;
  height: 224px;
  position: absolute;
  top: 0;
  width: 309px;
}

.categories .rectangle-8 {
  background-color: var(--pastelssea-pink);
  border-radius: 10px;
  height: 224px;
  position: absolute;
  top: 0;
  width: 309px;
}

.categories .one {
  left: 0;
}

.categories .two {
  left: -1401px;
}

.categories .property-1-one {
  left: 349px;
}

.categories .property-1-two {
  left: -1052px;
}

.categories .property-1-0-one {
  left: 698px;
}

.categories .property-1-0-two {
  left: -703px;
}

.categories .property-1-1-one {
  left: 1047px;
  width: 334px;
}

.categories .property-1-1-two {
  background-color: var(--pastelslightorchid);
  border-radius: 10px;
  left: -354px;
  width: 309px;
}

.categories .property-1-2-one {
  background-color: var(--pastelsmoonraker);
  border-radius: 10px;
  left: 1396px;
  width: 309px;
}

.categories .property-1-2-two {
  left: -25px;
  width: 329px;
}

.categories .property-1-3-one {
  left: 1745px;
}

.categories .property-1-3-two {
  left: 344px;
}

.categories .property-1-4-one {
  left: 2094px;
}

.categories .property-1-4-two {
  left: 693px;
}

.cat-img{
  width: 86%;
  border-radius: 10px;
  height: 30vh;
  object-fit: cover;
}