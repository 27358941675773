.title {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: calc(18px + 1vw);
    margin-right: auto;
}

.pricing-plans {
    position: relative;
}

.family-plans-button {
    position: absolute;
    right: 75px;
    width: 363px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    gap: var(--gap-3xs);
    color: var(--secondarymain);
}

.personal-plans-button {
    position: relative;
    right: 36px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    box-sizing: border-box;
    gap: var(--gap-3xs);
    color: var(--teritiaryfirstshade);
    font-size: calc(18px + 1vw);
  }

  .person-icon {
    position: relative;
    width: 45px;
    height: 45px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }

  .family-plans-button {
    position: relative;
    right: 36px;
    width: 402px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    gap: var(--gap-3xs);
    color: var(--secondarymain);
  }