.lmask {
  position: fixed;
  height: 100%;
  width: 100%; 
  background-color: #ffffff;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;;
  opacity: 0.8;
  display: flex;
  &.fixed {
    position: fixed;
  }  
}

.loading-img{
  display: block;
  margin: auto;
  max-height: 100vh;
  width: 35%;
  max-width: 1097px;
}
