.subscription-flow-plus-moveme .arrow-back-icon {
    position: relative;
    width: 38px;
    height: 38px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }

  .subscription-flow-plus-moveme .family-restroom-icon {
    position: relative;
    width: 45px;
    height: 45px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .subscription-flow-plus-moveme .family-plans {
    align-self: stretch;
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
  }
  .subscription-flow-plus-moveme .arrow-back-icon1 {
    position: relative;
    width: 38px;
    height: 38px;
    overflow: hidden;
    flex-shrink: 0;
    top: 8px;
    object-fit: contain;
  }

  .subscription-flow-plus-moveme .pricing-plans {
    position: relative;
  }
  .subscription-flow-plus-moveme .for-less-than {
    margin: 0;
  }
  .subscription-flow-plus-moveme .for-less-than-container {
    position: relative;
    font-size: var(--font-size-xl);
    font-weight: 500;
  }

  .subscription-flow-plus-moveme .image-placeholder-icon {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-xl);
    max-width: 100%;
    overflow: hidden;
    height: 205px;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 0;
  }

  .subscription-flow-plus-moveme .features1 {
    position: relative;
    font-weight: 600;
    z-index: 0;
  }
  .subscription-flow-plus-moveme .arrow-drop-down-icon {
    position: absolute;
    margin: 0 !important;
    top: 10px;
    right: 18px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
  }
  .subscription-flow-plus-moveme .subscribe-now-button-standard {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .subscription-flow-plus-moveme .features {
    align-self: stretch;
    border-radius: var(--br-xl);
    background-color: var(--teritiarysecondshade);
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-xl);
    position: relative;
    gap: var(--gap-7xs);
  }
  .subscription-flow-plus-moveme .subscribe-now-button-standard {
    border-radius: var(--br-21xl);
    background-color: var(--secondarymain);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    padding: 10px var(--padding-mini);
    color: var(--backgroundprimary);
  }
  .subscription-flow-plus-moveme .featurescta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .subscription-flow-plus-moveme .featurescta {
    align-self: stretch;
    gap: var(--gap-xl);
    z-index: 3;
  }

  .subscription-flow-plus-moveme .featurescta1,
  .subscription-flow-plus-moveme .plan-card1,
  .subscription-flow-plus-moveme .subscribe-now-button-plus{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subscription-flow-plus-moveme .subscribe-now-button-plus {
    border-radius: var(--br-21xl);
    background-color: var(--labelquaternary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    flex-direction: row;
    padding: var(--padding-mini);
    color: var(--labelprimary);
  }
  .subscription-flow-plus-moveme .featurescta1,
  .subscription-flow-plus-moveme .plan-card1 {
    flex-direction: column;
  }
  .subscription-flow-plus-moveme .featurescta1 {
    align-self: stretch;
    gap: var(--gap-xl);
    z-index: 3;
    color: var(--secondarymain);
  }
  .subscription-flow-plus-moveme .plan-card1 {
    position: absolute;
    top: calc(50% - 276px);
    left: calc(50% + 80px);
    border-radius: var(--br-xl);
    background-color: var(--backgroundprimary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    width: 346px;
    padding: var(--padding-8xs) var(--padding-3xs) var(--padding-3xs);
    box-sizing: border-box;
    gap: var(--gap-11xl);
    font-size: var(--font-size-xl);
    color: var(--labelquaternary);
  }
  .subscription-flow-plus-moveme {
    position: relative;
    width: 77%;
    min-height: calc(100vh - 93px - 200px);
    overflow: hidden;
    margin: auto;
    text-align: left;
    font-size: var(--font-size-17xl);
    color: var(--backgroundprimary);
    font-family: var(--font-montserrat);
    display: flex;
    flex-direction: column;
  }