.sign-in-up-button {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  position: relative;
  width: 452px;
}

.sign-in-up-button .text-wrapper {
  color: var(--backgroundprimary);
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5px + 0.23vw);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.greyed-out {
  background-color: var(--teritiarymain);
  opacity: 0.3;
  cursor: auto;
}

.sign-in-up-button.default {
  background-color: var(--teritiarymain);
}

.sign-in-up-button.pressed {
  background-color: var(--teritiaryfirstshade);
}
button{
  border:none;
}