.family-subscription-flow .arrow-back-icon {
    position: relative;
    width: 38px;
    height: 38px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .return-home {
    position: relative;
    font-weight: 500;
    color: var(--secondarymain);
  }
  .return-home-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: var(--padding-3xs);
    gap: var(--gap-3xs);
    margin: 50px 0;
  }
  
  .family-subscription-flow .personal-plans {
    align-self: stretch;
    flex: 1;
    position: relative;
    display: flex;
  }
  .family-subscription-flow .arrow-back-icon1 {
    position: relative;
    width: 38px;
    height: 53px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
  }
 
  .family-subscription-flow .for-less-than {
    margin: 0;
  }
  .family-subscription-flow .for-less-than-container {
    position: relative;
    font-size: calc(12px + .5vw);
    font-weight: 500;
  }
 

  .family-subscription-flow .features1 {
    position: relative;
    font-weight: 600;
    z-index: 0;
  }
  .family-subscription-flow .arrow-drop-down-icon {
    position: absolute;
    margin: 0 !important;
    top: 10px;
    right: 18px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
  }
  .family-subscription-flow .features,
  .family-subscription-flow .subscribe-now-button-standard {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .family-subscription-flow .features {
    align-self: stretch;
    border-radius: var(--br-xl);
    background-color: var(--teritiarysecondshade);
    justify-content: flex-start;
    padding: var(--padding-3xs) var(--padding-xl);
    position: relative;
    gap: var(--gap-7xs);
  }
  .family-subscription-flow .subscribe-now-button-standard {
    border-radius: var(--br-21xl);
    background-color: var(--secondarymain);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    justify-content: center;
    padding: var(--padding-mini);
    color: var(--backgroundprimary);
  }
  .family-subscription-flow .featurescta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .family-subscription-flow .featurescta {
    align-self: stretch;
    gap: var(--gap-xl);
    z-index: 3;
  }
  .family-subscription-flow .family1,
  .family-subscription-flow .plus {
    position: absolute;
    margin: 0 !important;
  }
  .family-subscription-flow .family1 {
    top: 120px;
    right: 54px;
    font-weight: 500;
    z-index: 1;
  }
  .family-subscription-flow .plus {
    top: 139px;
    right: 19px;
    font-size: var(--font-size-17xl);
    display: inline-block;
    z-index: 2;
  }
  .family-subscription-flow .featurescta1,
  .family-subscription-flow .plan-card1,
  .family-subscription-flow .subscribe-now-button-plus{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .family-subscription-flow .subscribe-now-button-plus {
    border-radius: var(--br-21xl);
    background-color: var(--labelquaternary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.family-subscription-flow .25);
    flex-direction: row;
    padding: var(--padding-mini);
    color: var(--labelprimary);
  }
  .family-subscription-flow .featurescta1,
  .family-subscription-flow .plan-card1 {
    flex-direction: column;
  }
  .family-subscription-flow .featurescta1 {
    align-self: stretch;
    gap: var(--gap-xl);
    z-index: 3;
    color: var(--secondarymain);
  }
  .family-subscription-flow .plan-card1 {
    position: absolute;
    top: calc(50% - 228px);
    left: calc(50% + 30px);
    border-radius: var(--br-xl);
    background-color: var(--backgroundprimary);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.family-subscription-flow .25);
    width: 346px;
    padding: var(--padding-8xs) var(--padding-3xs) var(--padding-3xs);
    box-sizing: border-box;
    gap: var(--gap-11xl);
    font-size: var(--font-size-xl);
    color: var(--labelquaternary);
  }
 .family-subscription-flow {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    /* background-image: url(/public/img/a06871876e7f9f98e423006838816909.gif); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: left;
    font-size: var(--font-size-17xl);
    color: var(--backgroundprimary);
    font-family: var(--font-montserrat);
    display: flex;
    flex-direction: column;
  }