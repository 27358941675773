.order-details-item-head{
    color: var(--secondarymain);
    font-size: calc(10px + 0.5vw);
    font-weight: 600;
}

.item-width{
    width: 234px;
}

.price-width{
    width: 91px;
}

.local-stream{
    height: 300px;
}