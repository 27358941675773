.star {
  position: relative;
}

.star .img {
  position: absolute;
}

.star.star-1 {
  height: 165px;
  width: 165px;
}

.star.star-2 {
  height: 259px;
  width: 259px;
}

.star.star-1 .img {
  height: 113px;
  left: 23px;
  top: 30px;
  width: 114px;
}

.star.star-2 .img {
  height: 234px;
  left: 6px;
  top: 0;
  width: 246px;
}

@media screen and (max-width: 950px) {
  .star{
    display: none;
  }
}