.choose-service-provider {
    position: relative;
    font-size: var(--font-size-13xl);
    font-weight: 600;
    margin-top: 50px;
    color: #001E61;
    font-family: 'Montserrat';
    text-align: center;
    padding-bottom: 10px;
}

.service-provider-button {
    border-radius: var(--br-3xs);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: 3px solid var(--secondarymain);
    flex-direction: row;
    padding: var(--padding-81xl) var(--padding-31xl);
}

.service-provider-button,
.service-provider-button1,
.tamreednet2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scan-img {
    max-width: 95%;
    max-height: 203px;
}

.labs-container-slider {
    overflow: auto;
    max-width: 100%;
    height: 161px;
}

.provider-content {
    color: var(--secondarymain);
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: calc(18px + 0.5vw);
}

.suppliers-carousel {
    max-width: 100%;
    overflow: auto;
    padding-bottom: 20px;
}

.sm-scan-img {
    max-width: 187px;
    max-height: 103px;
}

.provider-sm-div {
    max-height: 130px;
}

.btns-labs-scan{
    margin: auto;
    width: fit-content;
    margin-top: 50px;
    position: relative;
}