.egp1 {
  font-weight: 300;
}
.egp-txt {
  color: #000;
font-family: Montserrat;
font-size: 32px;
font-style: normal;
line-height: normal;
letter-spacing: -0.48px;
}
.egp,
.per-month {
  position: relative;
  letter-spacing: -0.01em;
}
.egp {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.per-month {
  font-weight: 500;
}
.per-monthly-price-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 3px;
  font-size: var(--font-size-xl);
  width: max-content;
}
.billing-frame-group {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 6px;
}













.return-home-btn,
.sub-menu-overview,
.welcome-banner-parent {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.sub-menu-overview {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-10xl) 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--labelprimary);
  font-family: var(--font-montserrat);
  height: 100vh;
}

.frame-main-content {
  align-self: stretch;
  border-radius: var(--br-3xs);
  border: 2px solid var(--mid-gray);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 25px 42px var(--padding-xl) 38px;
  gap: var(--gap-xl);
  width: 95%;
  margin: 22px;
}

.plan-type-selector {
  width: 484px;
  flex-direction: column;
  gap: 36px;
  min-width: 484px;
}
.plan-type-selector {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.active-plan-indicator {
  align-self: stretch;
  gap: var(--gap-mini);
  max-width: 100%;
}
.active-plan-indicator,
.plan-activity-indicator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.plan-type-placeholder {
  margin: 0;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.01em;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  max-width: 100%;
}
.plan-activity-indicator {
  border-radius: var(--br-3xs);
  border: 2px solid var(--labelprimary);
  padding: 0 12px 0 var(--padding-5xs);
  gap: var(--gap-3xs);
  font-size: var(--font-size-xl);
}
.e-g-p-price-frame {
  height: 10px;
  width: 10px;
  position: relative;
  border-radius: 50%;
  background-color: var(--labelprimary);
}
.payment-method-label {
  flex-direction: row;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  font-size: var(--font-size-xl);
}
.family-members {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}
.image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border: 2px dashed var(--labelprimary);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.amount-label {
  margin: 0;
  position: absolute;
  top: 10px;
  left: 18px;
  font-size: inherit;
  letter-spacing: -0.01em;
  font-weight: 500;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.add-family-member {
  height: 50px;
  width: 50px;
  position: relative;
  font-size: var(--font-size-5xl);
  cursor: pointer;
}
.family-memebers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.expiry-date-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  max-width: 100%;
}
@media screen and (max-width: 975px) {
  .plan-type-placeholder {
    font-size: var(--font-size-7xl);
  }
  .plan-type-selector {
    flex: 1;
  }
  .frame-main-content {
    flex-wrap: wrap;
  }
  .welcome-banner-parent {
    min-width: 100%;
  }
}


