.product-detailsplaceholder-parent {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 36px 6px rgba(2, 2, 70, 0.12);
  height: -moz-fit-content;
  height: fit-content;
  min-width: 289px;
}