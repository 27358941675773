.logo-icon {
    position: relative;
    width: 126px;
    height: 84px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .logostore-buttons,
  .social-media1 {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  .logostore-buttons {
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
  }
  .social-media1 {
    flex-direction: row;
    justify-content: center;
    padding: var(--padding-10xs-5);
  }
  .icon-facebook,
  .icon-whatsapp {
    position: relative;
    width: 35px;
    height: 35px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .icon-whatsapp {
    width: 38px;
    height: 38px;
  }
  .socials-icons {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
  }
  .for-doctors-items,
  .social-media {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-mini);
  }
  .for-doctors-items {
    justify-content: flex-start;
    gap: var(--gap-8xs);
    font-size: var(--font-size-base);
  }
  .contact-us {
    position: relative;
    font-weight: 800;
  }
  .for-doctors-items1 {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-8xs);
    font-size: var(--font-size-base);
  }
  .download-on-the-app-store-badg-icon,
  .google-play-badge-icon {
    position: relative;
    width: 129px;
    height: 37px;
    object-fit: cover;
  }
  .google-play-badge-icon {
    width: 149px;
    height: 57px;
  }
  .store-buttons {
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    padding: var(--padding-3xs) 0;
    gap: var(--gap-9xs);
  }
  .footer,
  .main-frame,
  .store-buttons {
    display: flex;
    justify-content: flex-start;
  }
  .footer {
    background-color: var(--secondarymain);
    width: 100%;
    margin: auto;
    flex-shrink: 0;
    flex-direction: row;
    align-items: flex-start;
    padding: var(--padding-xl) var(--padding-31xl);
    box-sizing: border-box;
    gap: var(--gap-31xl);
    z-index: 0;
    font-size: var(--font-size-xl);
    color: var(--backgroundprimary);
    z-index:4;
    position: fixed;
    bottom:0;
  }

  .white{
    color: var(--backgroundprimary) !important;
  }

  .qr-img{
    width: 117px;
    image-rendering: pixelated;
  }