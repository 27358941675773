.text-align-start{
    text-align: start;
}

.height77vh{
    height: 77vh;
}

.link{
    color: rgba(var(--bs-link-color-rgb)) !important
}

@media screen and (max-width: 950px) {
    .text-align-start{
        text-align: center;
    }
}