.steps-bar-icon {
    top: 45px;
    width: 619.71px;
    object-fit: cover;
    position: relative;
    margin: auto;
}

.check-booking-details,
.checkout,
.payment-details {
    font-weight: 600;
}

.check-booking-details {
    left: 369px;
    color: var(--teritiaryfirstshade) !important;
}

.payment-details {
    left: calc(50% - 67px);
}

.checkout {
    right: 427px;
}

.bar-text {
    position: relative;
    top: 55px;
    gap: 194px;
}