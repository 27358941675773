.arrow-back-ios-icon {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.active-indicator {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.active {
  height: fit-content !important;
}

.visual-placeholder-icon {
  position: relative;
  width: 710px;
  height: 387px;
  object-fit: cover;
  mix-blend-mode: normal;
}

.lorem-ipsum,
.lorem-ipsum-dolor {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 600;
}

.lorem-ipsum {
  color: var(--secondarymain);
  font-family: "Montserrat";
  font-size: calc(5px + 1vw);
}

.slider-title {
  font-size: calc(30px + 1.6vw);
}

.lorem-ipsum-dolor {
  font-size: calc(15px + 0.2vw);
  font-family: "Montserrat";
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  width: 476px;
}

.orientation-textvisualarrow,
.text-landing {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.text-landing {
  height: 384px;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-mid);
}

.orientation-textvisualarrow {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-26xl);
}

.left,
.middle {
  position: relative;
  border-radius: 50%;
  background-color: var(--teritiarymain);
  width: 20px;
  height: 20px;
}

.middle {
  background-color: var(--backgroundprimary);
  opacity: 0.35;
}

.orientation-slider,
.slider-spheres {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-6xl);
}

.orientation-slider {
  flex-direction: column;
  gap: var(--gap-22xl);
}

.book-online-icon {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  width: calc(22px + 2vw)
}

.examination-or-proceedure {
  position: relative;
  font-size: calc(6px + .5vw);
  letter-spacing: -0.01em;
  font-weight: 600;
}

.quick-action-bar-text {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.book-a-doctor,
.headset-mic-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.book-a-doctor {
  flex: 1;
  border-radius: var(--br-xl) 0 0 0;
  overflow: hidden;
  justify-content: center;
  /* padding: var(--padding-mini) 30px; */
  gap: var(--gap-3xs);
  z-index: 0;
  cursor: pointer;
  height: 103px;
  min-width: fit-content;
}

.non-active-tab {
  background-color: var(--backgroundteritiary);
}

.active-tab{
  background-color: white;
  border: solid 2px var(--teritiarymain) !important;
}

.headset-mic-wrapper {
  justify-content: flex-start;
}

.examination-or-proceedure1 {
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: -0.01em;
  font-weight: 600;
  color: var(--secondarymain);
}

.quick-action-bar-text1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.telehealth {
  flex: 1;
  overflow: hidden;
  justify-content: center;
  /* padding: var(--padding-3xs) 30px; */
  box-sizing: border-box;
  gap: var(--gap-3xs);
  z-index: 1;
  cursor: pointer;
  height: 103px;
  min-width: fit-content;
}

.book-a-doctor3,
.home-visit,
.telehealth {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.book-a-doctor3 {
  flex-shrink: 0;
  justify-content: flex-start;
}

.home-visit {
  flex: 1;
  border-radius: 0 var(--br-xl) 0 0;
  overflow: hidden;
  justify-content: center;
  /* padding: var(--padding-3xs) 30px; */
  gap: var(--gap-3xs);
  z-index: 2;
  cursor: pointer;
  height: 103px;
  min-width: fit-content
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
  color: var(--teritiarymain);
  background-color: var(--teritiarymain) !important;
  width: 19px !important;
  height: 19px !important;
}

.teritiarymain {
  color: var(--teritiarymain) !important;
}

.react-slideshow-container+ul.indicators li {
  width: fit-content !important;
  height: auto !important;
}

.quick-actions-bar {
  align-self: stretch;
  border-radius: var(--br-xl) var(--br-xl) 0 0;
  border-bottom: 0.5px solid var(--mid-gray);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.text-placeholer {
  flex: 1;
  position: relative;
}

.keyboard-arrow-up-icon {
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.input-field,
.search {
  border-radius: var(--br-3xs);
  background-color: var(--backgroundprimary);
  border: 1px solid var(--mid-gray);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 var(--padding-3xs);
}

.search {
  width: 360px;
  justify-content: center;
  gap: var(--gap-41xl);
  height: 42px;
}

.input-field {
  width: 300px;
  height: 40px;
  justify-content: flex-start;
}

.search-icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.search2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.booking-search-fields,
.search-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.search-button {
  border-radius: var(--br-3xs);
  background-color: var(--teritiarymain);
  width: 118px;
  height: 40px;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  box-sizing: border-box;
  gap: var(--gap-6xs);
  font-size: var(--font-size-lg);
  color: var(--backgroundprimary);
  cursor: pointer;
}

.booking-search-fields {
  flex: 1;
  border-radius: 0 0 var(--br-3xs) var(--br-3xs);
  background-color: var(--backgroundprimary);
  align-items: flex-start;
  padding: var(--padding-21xl) var(--padding-12xl);
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--mid-gray);
  width: 100%;
}

.orientationquickactions,
.quick-actions {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quick-actions {
  border-radius: var(--br-xl);
  box-shadow: 14px 12px 30px rgba(0, 0, 0, 0.25);
  justify-content: center;
  font-size: var(--font-size-13xl);
  color: var(--labelprimary);
  width: 95%;
}

.form-select {
  border: 1px solid var(--mid-gray);
  height: 42px;
  border-radius: var(--br-3xs);
  color: var(--mid-gray);
}

.form-select option {
  color: var(--labelprimary)
}

.orientationquickactions {
  width: 100%;
  justify-content: flex-start;
  gap: 30px;
  z-index: 2;
  text-align: center;
  color: var(--secondarymain);
}

.ask-a-doctor1 {
  position: relative;
  z-index: 0;
}

.submit-a-medical {
  margin: 0;
}

.submit-a-medical-container {
  position: relative;
  font-weight: 500;
  text-align: start !important;
  width: max-content;
}

.submit-question {
  position: relative;
}

.submit-question-button,
.textbutton {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.submit-question-button {
  border-radius: var(--br-31xl);
  background-color: var(--teritiarymain);
  flex-shrink: 0;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-3xs);
  font-size: var(--font-size-5xl);
}

[hidden] {
  display: none;
}

.textbutton {
  justify-content: flex-start;
  gap: var(--gap-mini);
  z-index: 1;
  font-size: var(--font-size-xl);
}

.doctor-image-icon {
  bottom: 0;
  width: 347px;
  object-fit: cover;
  z-index: 2;
  position: relative;
  top: -23px;
}

.ask-a-doctor {
  align-self: stretch;
  background-color: var(--primarymain);
  height: 178px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-26xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-41xl);
  width: 100%;
}

.medical-tip-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.medical-tip {
  position: relative;
  width: 100%;
}

.askdoctortips {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-31xl);
  z-index: 1;
  font-size: var(--font-size-45xl);
  margin-top: 100px;
}

.main-frame {
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  background-image: url(/public/img/background-landing.jpg);
  background-size: cover;
  padding-top: 100px;
  height: 950px;
}

.slogan-logo{
  width: 25%;
  min-width: 225px;
  margin-left: auto;
  margin-right: 16%;
}

.slogan-text{
  color: var(--secondarymain);
  font-size: calc(8px + 0.8vw);
  font-family: 'Montserrat';
  text-align: center;
  font-weight: 600;
  text-shadow: 1.41px 1.41px white, 2px 0 white, 1.41px -1.41px white, 0 -2px white, -1.41px -1.41px white, -2px 0 white, -1.41px 1.41px white, 0 2px white;
}

.clinic {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.landing-page-logged-out {
  position: relative;
  background-color: var(--backgroundprimary);
  width: 100%;
  position: absolute;
  top: 4px;
  bottom: 100px;
  overflow-y: auto;
  text-align: left;
  font-size: var(--font-size-41xl);
  color: var(--backgroundprimary);
  font-family: var(--font-montserrat);
}

html {
  overflow: hidden;
}

.text-lorem {
  width: 50%;
}

.fit-content {
  width: fit-content
}

.footer,
.landing-page-logged-out,
.clinic-div, .tele-health-div, .home-visit-div {
  position: relative !important;
  top: 0px;
  overflow: hidden;
}

.navbarsearch-bar .notifications-icon{
  position: relative;
  top: 23px;
  width: calc(0.5vw + 26px);
}

@media screen and (max-width: 900px) {
  .notifications-div{
    width: 76vw !important;
  }

  html {
    overflow: auto;
  }

  .ask-a-doctor{
    display: block !important;
    padding: 0 20px !important;
  }

  .navbar1 {
    width: auto;
    height: auto;
  }

  .navbarsearch-bar {
    position: relative;
  }


  .main-frame {
    position: relative;
    height: auto;
    padding-top: 36px;
  }

  .navbar {
    height: auto;
  }

  .quick-actions {
    border: .5px solid var(--secondarymain);
    padding: 15px;
    background-color: var(--backgroundprimary);
  }

  .quick-actions-bar,
  .ask-a-doctor {
    flex-direction: row;
    height: auto;
    flex-wrap: wrap;
  }

  .quick-actions-bar-text{
    align-items: center;
  }

  .doctor-image-icon {
    position: relative;
    top: auto !important;
  }

  .book-a-doctor,
  .home-visit,
  .telehealth {
    width: 50%;
    padding: 15px;
    min-width: auto;
    flex: auto !important;
    flex-wrap: wrap;
    height: 128px;
  }

  .horizontal {
    height: fit-content;
    margin-bottom: 140px !important;
  }

  .text-lorem {
    height: fit-content;
    width: fit-content;
  }

  .lorem-ipsum-dolor {
    width: 100%;
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .visual-placeholder-icon {
    height: auto;
    width: 100%;
  }

  .askdoctortips {
    width: 100%;
  }

  .search-bar {
    position: relative;
    top: 0px;
  }

  .active-indicator {
    bottom: 0;
  }

  .home-visit{
    border-radius: 0;
  }
}

@media screen and (max-width:650px) {
  .slogan-logo{
    margin: auto !important;
  }

  .lorem-ipsum{
    font-size: calc(10px + .9vw) !important;
  }
}

.slider-img{
  height: 238px;
  object-fit: contain;
}

.slider-header{
  background-color: var(--navbar);
  margin-bottom: 31px;
}