.text {
  position: relative;
  width: 193px;
  height: 59px;
}

.text .patient {
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5px + 1.6vw);
  font-weight: 800;
  height: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;

}

.text .default {
  color: var(--secondarymain);
}

.text .highlighted {
  color: var(--primarymain);
}
