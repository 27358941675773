.notifications-div {
    display: flex;
    width: 450px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 1px solid var(--BackgroundQuaternary, #AFAFAF);
    background: var(--BackgroundPrimary, #FFF);
    position: absolute;
    z-index: 1;
}

.notification-content{
    height: 381px;
    overflow: auto;
}

.notification-row{
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
}

.rectangle-arrow {
    width: 46px;
    height: 43.819px;
    position: absolute;
    top: -9px;
    right: -237px;
}

.notification-title{
    color: var(--labelprimary);
    font-family: 'Montserrat';
    font-size:  20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.notification-header{
    border-bottom: 1px solid var(--BackgroundQuaternary, #AFAFAF);
    padding-bottom: 20px;
    width: 100%;
}

.primarylabel{
    color: var(--labelprimary);
}