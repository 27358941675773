.sign-in-flow {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.sign-in-flow .overlap-group-wrapper {
  background-color: var(--backgroundprimary);
  height: 100vh;
  overflow: hidden;
  width: 100%;
}

.sign-in-flow .overlap-group {
  position: relative;
  width: 100%;
  flex-direction: row;
}

.sign-in-flow .rectangle {
  background-color: var(--primarymain);
    position: relative;
    top: 0;
    height: 100vh;
    max-width: 640px;
    width:46%;
}

.sign-in-flow .illustration {
  width: 133%;
  max-width: 860px;
  height: 65vh;
  max-height: 607px;
}

.sign-in-flow .logo {
  margin-left: auto;
  margin-right: auto;
  width:25%
}

.sign-in-flow .text-wrapper-2 {
  color: var(--backgroundprimary);
  font-family: "Montserrat", Helvetica;
    font-size: calc(18.5px + 1.6vw);
  font-weight: 800;
  left: 163px;
  letter-spacing: 0;
  line-height: normal;
}

.sign-in-flow .doxx-is-an {
  color: var(--backgroundprimary);
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5px + 0.1vw);
  font-weight: 500;
  left: 165px;
  letter-spacing: 0;
  line-height: normal;
  padding-left: 18px;
}

.sign-in-flow .group {
  position: relative;
  width: 456px;
  margin: auto;
}

.sign-in-flow .p {
  color: var(--secondarymain);
  font-family: "Montserrat", Helvetica;
  font-size: calc(18.5px + 0.7vw);
  font-weight: 600;
  height: 39px;
  left: 34px;
  letter-spacing: 0;
  line-height: normal;
}

.sign-in-flow .email-input-instance {
  border-color: var(--labelprimary) !important;
  left: 0 !important;
}

.sign-in-flow .password-input-instance {
  border-color: var(--labelprimary) !important;
  left: 0 !important;
}

.conf-password-input-instance{
  border-color: var(--labelprimary) !important;
  left: 0 !important;
}

.sign-in-flow .sign-in-up-button-instance {
  left: 0 !important;
}

.sign-in-flow .text-wrapper-3 {
  color: var(--primarymain);
  font-family: "Montserrat", Helvetica;
   font-size: calc(18.5px + 0.1vw);
  font-weight: 600;
  left: 107px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.sign-in-flow .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  left: 38px;
}

.sign-in-flow .text-wrapper-4 {
  color: var(--labelprimary);
  font-family: "Montserrat", Helvetica;
   font-size: calc(18.5px + 0.1vw);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.sign-in-flow .text-wrapper-5 {
  color: var(--primarymain);
  font-family: "Montserrat", Helvetica;
   font-size: calc(18.5px + 0.1vw);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.fit-content{
  width: fit-content
}

::placeholder{
  color: var(--Mid-Gray, #7E7E7E);
  font-family: Montserrat;
   font-size: calc(18.5px + 0.1vw);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.search-input::placeholder{
  position: absolute;
  left: 18px;
  top: 22px;  
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a{
  text-decoration: none;
}

.type-ask{
  color: var(--primarymain);
  margin-top: 20px !important;
}

.auth-error{
  top:-21px;
}

.PhoneInputInput{
  direction: inherit;
}

.select-login{
  color: var(--mid-gray);
  background-color: transparent;
  height: 46px !important;
  font-size: 20px !important;
}

/* If the screen becomes smaller */

@media screen and (max-width: 650px) {
  .rectangle {
    width: 100% !important;
    max-width: none !important;
    height: auto !important;
  }

  .sign-in-flow .overlap-group {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    overflow: auto;
  }

  .sign-in-flow .group {
    width: 100%;
    margin-top: 69px;
  }

  .sign-in-flow .img-container{
    display: none;
  }

  input , .email-input, .sign-in-up-button{
    width: 90% ;
    margin: auto !important;
  }

  .sign-in-up-button{
    margin-bottom: 20px !important;
  }

  .text-danger{
    left: 6%
  }

  .eye-svg-left{
    left:85%;
  }
  
  .eye-svg-right{
    right:85%
  }

}
