.cart-footer {
    position: relative;
    width: 99%;
}

.cart-return-button {
    
    bottom: 26px;
    left: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs);
    gap: var(--gap-3xs);
    font-size: var(--font-size-17xl);
  }

  .cart-proceed-to-payment .div  {
    position: relative;
    font-weight: 600;
  }

  .cart-proceed-to-payment {
    display: flex;
  }
  .cart-proceed-to-payment {
    border-radius: var(--br-3xs);
    background-color: var(--color-deepskyblue);
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-3xs);
    gap: var(--gap-3xs);
    cursor: pointer;
    color: var(--color-white);
    width: fit-content;
    align-self: end;
  }

  .total{
    color: var(--secondarymain);
  }