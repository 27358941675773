.navbar-button-clinic,
.navbar-button-nursing,
.navbar-button-sign-up,
.navbar1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-button-nursing {
  align-self: stretch;
  flex: 1;
  padding: var(--padding-8xs) 0;
  color: var(--color-dodgerblue);
}

.frame-nurse {
  position: absolute;
  left: 0;
  width: 100%;
  top: 78px;
  right: 0;
  border-radius: var(--br-3xs);
  background-color: #c7e0b8;
  height: 208px;
}

.the-home-nursing {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: var(--secondarymain);
}

.get-the-medical {
  margin: 0;
}

.get-the-medical-container {
  position: relative;
  font-size: var(--font-size-sm);
  letter-spacing: -0.01em;
}

.cutout-1-icon,
.tov-nurse {
  position: absolute;
  border-radius: var(--br-3xs);
}

.tov-nurse {
  width: calc(100% - 936.11px);
  top: 102px;
  right: 910.94px;
  left: 25.17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
}

.cutout-1-icon {
  width: calc(100% - 1007.68px);
  top: 0;
  right: 0;
  left: 1007.68px;
  max-width: 100%;
  overflow: hidden;
  height: 286px;
  object-fit: cover;
}

.feature-banner-nurse {
  position: absolute;
  width: calc(100% - 46px);
  top: 121px;
  right: 23px;
  left: 23px;
  height: 286px;
  color: var(--labelprimary);
}

.service-provider-button {
  border-radius: var(--br-3xs);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border: 3px solid var(--secondarymain);
  flex-direction: row;
  padding: var(--padding-81xl) var(--padding-31xl);
  height: 219px;
  width: 337px ;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--LabelPrimary, #000);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;
  max-width: 100%;
}



.providers-carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.providers-carousel {
  background-color: var(--backgroundprimary);
  border-top: 3px solid var(--labelprimary);
  border-bottom: 3px solid var(--labelprimary);
  justify-content: center;
  padding: var(--padding-11xl) 87px;
  gap: var(--gap-149xl);
  font-size: var(--font-size-13xl);
  color: var(--labelprimary);
}

.nursing {
  width: 100%;
  position: relative;
  background-color: var(--backgroundprimary);
  min-height: calc(100vh - 314px);
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--secondarymain);
  font-family: var(--font-montserrat);
}