.add-family-head {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
}

.sub-add-family {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
}

.title-label-family {
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
}

.add-family-font-style {
    color: #000;
    text-align: center;
    font-family: Montserrat;
}

.add-member-btn {
    color: var(--BackgroundPrimary, #FFF);
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    border-radius: 10px;
    background: var(--SecondaryMain, #001E61);
    padding: 8px 20px;
    margin: auto;
    display: block;
}

.family-member-close-img{
    position: absolute;
    right: 16px;
    top: 11px;
    width: 21px;
    cursor: pointer;
}