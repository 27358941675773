.ask-doc-div-en {
    display: flex;
    width: 30%;
    height: 178px;
    padding: 0px 20px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--PrimaryMain, #0366FE);
    position: fixed;
    bottom: 20px;
    right: 54px;
    color: var(--BackgroundPrimary, #FFF);
    font-family: 'Montserrat';
    text-align: start;
    z-index: 20;
    min-width: 226px;
}

.ask-doc-div-ar{
    display: flex;
    width: 30%;
    height: 178px;
    padding: 0px 20px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--PrimaryMain, #0366FE);
    position: fixed;
    bottom: 20px;
    left: 54px;
    color: var(--BackgroundPrimary, #FFF);
    font-family: 'Montserrat';
    text-align: end;
    z-index: 20;
    min-width: 226px;
}

.ask-doc-title {
    font-size: calc(12px + .5vw);
    font-weight: 700;
    line-height: normal;
}

.ask-doc-content {
    font-size: calc(10px + .2vw);
    font-style: normal;
    font-weight: 500;
    width: 60%;
}

.submit-question-button{
    width: fit-content
}

.doctor-img-ask{
    position: absolute;
    right: -25px;
    bottom: 0;
    max-width: 231px;
    width: 53%;
}

.doctor-img-ask-ar{
    left: -25px;
    position: absolute;
    top: -53px;
    max-width: 231px;
    width: 53%;
}

.minimize-icon-ask{
    width: 21px;
    position: absolute;
    right: 16px;
    top: 8px;
    cursor: pointer;
    z-index: 3;
}

.minimize-icon-ask-ar{
    width: 21px;
    position: absolute;
    left: 16px;
    top: 8px;
    cursor: pointer;
    z-index: 3;
}

.ask-doc-div-minimize{
    width: 211px !important;
    height: 37px !important;
    bottom: 8px !important;
}