.checkbox-icon {
    outline: black 2px solid;
}

.male {
    font-size: 16px;
    font-family: "Montserrat";
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    accent-color: rgba(0, 0, 0, 1);
    height: fit-content;
    width: fit-content;
    position: relative;
}

.female {
    flex: 1;
    position: relative;
    font-weight: 500;
}

.checkbox-label{
    position: relative;
    top:-4px;
    text-align: start;
}