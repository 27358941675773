.booking-payment-flow-1 .doctorservice-name-placeholde {
    position: relative;
    font-size: var(--font-size-5xl);
    font-weight: 800;
    color: var(--secondarymain);
    text-align: left;
}

.booking-payment-flow-1 .calendar-today-icon {
    position: relative;
    width: 30px;
    height: 30px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.booking-payment-flow-1 .select-dateicon {
    align-self: stretch;
    width: 148px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-8xs);
}

.cart-view .date .div {
    position: relative;
    line-height: 22px;
    font-weight: 600;
    font-size: 17px;
}

.cart-view .date,
.cart-view .select-date {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cart-view .date {
    width: 148px;
    justify-content: center;
    padding: var(--padding-3xs);
    box-sizing: border-box;
    color: var(--color-black);
}

.booking-payment-flow-1 .select-date {
    justify-content: flex-start;
}

.cart-view .time-slot-p {
    position: relative;
    line-height: 22px;
}

.cart-view .select-time-slot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs);
    gap: var(--gap-8xs);
}

.cart-view .time-slot1 {
    border-radius: var(--br-3xs);
    background-color: var(--primarymain);
    padding: var(--padding-3xs) var(--padding-11xl);
    box-sizing: border-box;
    font-size: var(--font-size-lg);
    color: var(--backgroundprimary);
    justify-content: center;
    display: flex;
}

.cart-view .select-time-slot-parent,
.cart-view.time-slot1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.booking-payment-flow-1 .booking-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 0;
    width: 400px;
}

.booking-payment-flow-1 .cost,
.booking-payment-flow-1 .egp {
    position: relative;
    font-weight: 500;
}

.booking-payment-flow-1 .egp {
    font-size: 32px;
    font-weight: 600;
    color: var(--secondarymain);
}

.booking-payment-flow-1 .booking-details-parent,
.booking-payment-flow-1 .price {
    display: flex;
    align-items: center;
}

.booking-payment-flow-1 .price {
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    font-size: var(--font-size-mini);
    color: var(--color-black);
    margin-left: auto;
    margin-right: auto;
}

.booking-payment-flow-1 .booking-details-parent {
    background-color: var(--backgroundprimary);
    border-bottom: 3px solid var(--secondarymain);
    box-sizing: border-box;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--padding-11xl) 20px;
    text-align: center;
    font-size: var(--font-size-xl);
    color: var(--primarymain);
    top: 86px;
    position: relative;
    flex-wrap: wrap;
}



.booking-payment-flow-1 .proceed-to-payment1 {
    position: relative;
    font-weight: 600;
}

.booking-payment-flow-1 .proceed-to-payment {
    right: 34px;
    bottom: 33px;
    border-radius: var(--br-3xs);
    background-color: var(--teritiarymain);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs);
    gap: var(--gap-3xs);
    cursor: pointer;
    font-size: var(--font-size-5xl);
    color: var(--backgroundprimary);
}

.booking-payment-flow-1 .arrow-back-icon {
    position: relative;
    width: 38px;
    height: 38px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.booking-payment-flow-1 .return-button {
    bottom: 26px;
    left: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-3xs);
    gap: var(--gap-3xs);
    font-size: var(--font-size-17xl);
    color: var(--secondarymain);
}

.booking-payment-flow-1 {
    position: relative;
    background-color: var(--backgroundprimary);
    width: 100%;
    height: 100vh;
    overflow: auto;
    text-align: left;
    font-size: var(--font-size-mini);
    font-family: var(--font-montserrat);
    display: flex;
    flex-direction: column;
}

.booking-rows-parent {
    position: relative;
    width: 70%;
}

.mr-auto {
    margin-right: auto !important;
}

.payment-p {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
}

.select-address-div{
    position: relative;
    padding: 35px;
    top: 88px;
}

.new-add-btn{
    top: 108px;
    border-radius: var(--br-81xl);
    background-color: var(--teritiarymain);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-3xs);
    height: 68px;
}

.doctor-appointment-div{
    width: 82%;
    gap: 14px;
}